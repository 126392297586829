import { Api } from "./api"
import { GetDoctorsResult, GetDoctorResult, DeleteModelResult } from "./api.types"
import { getGeneralApiProblem } from "./api-problem"
import { Doctor } from "src/models/doctor/doctor"

export class DoctorApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }
  
  async get(id: number|string) : Promise<GetDoctorResult> {
    throw new Error("get not implemented in DoctorApi")
  }

  async getAll() : Promise<GetDoctorsResult> {
    try {
      const response = await this.api.getDoctors()

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const payload = response.data

      return { kind: 'ok', payload }
    } catch (e) {
      return { kind: "bad-data" }
    }
  }

  async createOrUpdate(doctor: Doctor) : Promise<GetDoctorResult> {
    try {
      const response = doctor.id ? await this.api.updateDoctor(doctor) : await this.api.createDoctor(doctor);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const payload = response.data

      return { kind: 'ok', payload }
    } catch (e) {
      return { kind: "bad-data" }
    }
  }

  async delete(doctor: Doctor) : Promise<DeleteModelResult> {
    try {
      const response = await this.api.deleteDoctor(doctor);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: 'ok' }
    } catch (e) {
      return { kind: "bad-data" }
    }
  }
}
