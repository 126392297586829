import { useEffect } from "react";
import { useStores } from "../../models";
import { Box, Typography } from "@mui/material";
import { IColumn, CustomTable } from "src/components/lists/CustomTable";
import { ReportType } from "../../models/reportTypes/report-type";
import { translate } from "../../i18n";
import { observer } from "mobx-react";

export const ReportTypeList = observer(() => {
    const { reportTypeStore } = useStores();

    const columns: IColumn<ReportType>[] = [
        { field: 'code', headerName: 'Code', width: 130, getValue: (ReportType: ReportType) => ReportType.code ?? '' },
    ];

    useEffect(() => {
        (async() => {
            await reportTypeStore.getAll();
        })();
    });

    return (
        <Box className="page">
            <Typography variant="h1" component="h1" gutterBottom>
                { translate('reportType.list') }
            </Typography>
            {
                reportTypeStore.models && (
                    <CustomTable<ReportType>
                        columns={columns}
                        data={reportTypeStore.models}
                        id={(model: ReportType) => {
                            return model.id.toString()
                        }}
                        addButton={{
                            text: translate('reportType.create') ?? ''
                        }}
                        addEditModal={
                            {
                                store: reportTypeStore,
                                columns: [
                                    {
                                        attr: 'code',
                                        label: translate('reportType.code') ?? ''
                                    }
                                ]
                            }
                        }
                        deleteModal={
                            {
                                title: (reportType: ReportType) =>  translate('common.delete', { "element": reportType?.code ?? '' }) ?? "",
                                store: reportTypeStore
                            }
                        }
                        actions={{width: 130}}
                    />
                )
            } 
        </Box>
    )
})