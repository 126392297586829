import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * Text Bloc model.
 */
 export const ParagraphModel = types.model("Paragraph").props({
    id: types.identifierNumber,
    lang: types.maybeNull(types.string),  
    // title: types.maybeNull(types.string), 
    section_id: types.optional(types.maybeNull(types.number), null),
    lang_id: types.maybeNull(types.number),
    sentences: types.optional(types.maybeNull(types.string), ""),
  })
  
  export type ParagraphType = Instance<typeof ParagraphModel>
  export interface Paragraph extends ParagraphType {}
  type ParagraphSnapshotType = SnapshotOut<typeof ParagraphModel>
  export interface ParagraphSnapshot extends ParagraphSnapshotType {}
  export const createExamDefaultModel = () => types.optional(ParagraphModel, {} as ParagraphType)