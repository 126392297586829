import { useEffect, useRef } from "react";
import { observer } from "mobx-react";
import dayjs from "dayjs";
import { Exam } from "../../models/exam/exam";
import { useStores } from "../../models";
import { Box, Button, IconButton, TextField, Card, Grid, Container, Typography, FormControlLabel, Accordion, AccordionSummary, FormGroup, Checkbox, Select, MenuItem, SelectChangeEvent, InputLabel, FormControl, CardContent, Alert } from "@mui/material";
import Image from 'mui-image';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/Expand';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { useNavigate } from "react-router-dom";
import QRCode from "react-qr-code";
import { translate } from "../../i18n";
import { DatePicker } from "@mui/x-date-pickers";
import Conclusions from "src/components/exams/Conclusions";
import { ConclusionList } from "src/models/conclusions/conclusion-list";
import { DATE_FORMAT_API, DATE_FORMAT_UI } from "../../config/env";
import { ErrorValidationBloc } from "../errors/ErrorValidationBloc";

interface IExamFormProps {
    exam: Exam;
    title: string;
    redirectAfterSave: boolean|undefined;
    setExam: (ex: Exam) => void;
}

interface EditButtonProps {
    onChange: (image: any) => void;
}
const EditButton = (props: EditButtonProps) => (
    <IconButton sx={{ backgroundColor: "white", position: "absolute", right: 5, top: 5 }} aria-label="settings" component="label">
        <input onChange={(event) => props.onChange(event.target?.files?.length ? event.target.files[0] : null)} hidden accept="image/*" type="file" />
        <EditIcon />
    </IconButton>
);

export const ExamForm = observer(({exam, title, redirectAfterSave, setExam}: IExamFormProps) => {

    const { doctorStore, examStore, langStore, patientStore, reportTypeStore, contextTypeStore, medicalModelStore, translationProviderStore } = useStores();

    const navigate = useNavigate();
    const errorBlocRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        (async () => {
            await reportTypeStore.getAll();
        })();
    }, [reportTypeStore]);

    useEffect(() => {
        (async () => {
            await doctorStore.getAll();
        })();
    }, [doctorStore]);

    useEffect(() => {
        (async () => {
            await patientStore.getAll();
        })();
    }, [patientStore]);

    useEffect(() => {
        (async () => {
            await langStore.getLangs();
        })();
    }, [langStore]);

    useEffect(() => {
        (async () => {
            await contextTypeStore.getContextTypes();
        })();
    }, [contextTypeStore]);

    useEffect(() => {
        (async () => {
            await medicalModelStore.getMedicalModels();
        })();
    }, [medicalModelStore]);

    useEffect(() => {
        (async () => {
            await translationProviderStore.getTranslationProviders();
        })();
    }, [translationProviderStore]);

    // const getImage = (): string => {
    //     const defaultImage = 'https://via.placeholder.com/640x360.png?text=No+Image';

    //     if(!exam.image_url) return defaultImage;

    //     if(exam.image_url.includes("http")) {
    //         return exam.image_url;
    //     }

    //     if(!exam.image) {
    //         return defaultImage;
    //     }
        
    //     return exam.image.trim() === '' ? defaultImage : `data:image/png;base64, ${exam.image}`;
    // };

    const ExamQrCode = () => {
        if(!exam?.hash || exam.hash.trim() === '') {
            return null;
        }

        return (
            <QRCode style={{ alignSelf: "flex-start", float: "left", justifySelf: "flex-start"}} value={`Vulgaroo | ${exam.hash}`} viewBox={`0 0 256 256`} />
        );
    }

    const uploadImage = async(image: any) => {
        const newExam = await examStore.uploadImage(exam, image);

        if(newExam) {
            setExam(newExam);
        }
    };
    
    const saveExam = async(event: React.MouseEvent<HTMLButtonElement>) => {
        try {
            await examStore.createOrUpdate(exam)

            if(redirectAfterSave) {
                navigate("/exams")
            }

        } catch {
            // scroll up to the error message
            if(errorBlocRef?.current) {
                errorBlocRef.current.scrollIntoView({ behavior: 'smooth' })
            }
        }
    }

    return (
        <Box className="page" sx={{
            marginTop: 1,
            
          }}>
                {
                    exam && (
                        <Box>
                            <Typography  gutterBottom component="h1" variant="h5">
                                {title}
                            </Typography>
                            
                            <ErrorValidationBloc error={examStore.validationError} />

                            <Accordion sx={{pl: 2, pr: 2}} defaultExpanded={false}>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                    <Typography>En-tête</Typography>
                                </AccordionSummary>
                            
                            <Grid container spacing={3}>                 
                                <Grid item xs={6}>
                                    <Select
                                        sx={{ width: '100%'}}
                                        value={exam.type ?? ""}
                                        error={examStore.hasError("type")}
                                        label={examStore.getError("type")}
                                        onChange={(event: SelectChangeEvent) => setExam(examStore.updateField(exam, 'type', event.target.value))}
                                    >
                                        {
                                            reportTypeStore?.models &&
                                                reportTypeStore.models.map((r) => (
                                                    <MenuItem key={`MenuItem_${r.code}`} value={r.code}>{r.code}</MenuItem>
                                                ))
                                        }
                                    </Select>
                                </Grid>
                                <Grid item xs={6}>
                                <TextField
                                    value={exam.exam_id}
                                    sx={{ width: '100%'}}
                                    error={examStore.hasError("exam_id")}
                                    helperText={examStore.getError("exam_id")}
                                    disabled={exam.id && exam.id > 0 ? true : false}
                                    onChange={(event) => setExam(examStore.updateField(exam, 'exam_id', event.target.value))}
                                    // label="Error"
                                    required={true}
                                    label={translate('exam.exam_id')}
                                    />
                                </Grid>
                                
                                <Grid item xs={6}>
                                <DatePicker
                                    label={translate('exam.examDate')}
                                    onChange={(value) => {
                                        setExam(examStore.updateField(exam, 'exam_date', dayjs(value, DATE_FORMAT_UI).format(DATE_FORMAT_API)))}
                                    }
                                    inputFormat={DATE_FORMAT_UI}
                                    value={dayjs(exam.exam_date, DATE_FORMAT_API).format("MM/DD/YYYY")}
                                    renderInput={(params) => <TextField sx={{ width: '100%'}} {...params} />}
                                />
                                </Grid>
                                <Grid item xs={6}>
                                <DatePicker
                                    label={translate('exam.reportDate')}

                                    onChange={(value) => {
                                        setExam(examStore.updateField(exam, 'report_date', dayjs(value, DATE_FORMAT_UI).format(DATE_FORMAT_API)))}
                                    }
                                    inputFormat={DATE_FORMAT_UI}
                                    value={dayjs(exam.report_date, DATE_FORMAT_API).format("MM/DD/YYYY")}
                                    renderInput={(params) => <TextField sx={{ width: '100%'}} {...params} />}
                                />
                                </Grid>
                                <Grid item xs={6}>
                                <TextField
                                    sx={{ width: '100%'}}
                                    value={exam.place}
                                    error={examStore.hasError("place")}
                                    helperText={examStore.getError("place")}
                                    onChange={(event) => setExam(examStore.updateField(exam, 'place', event.target.value))}
                                    label={translate('exam.place')}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                <TextField
                                    sx={{ width: '100%'}}
                                    error={examStore.hasError("hash")}
                                    helperText={examStore.getError("hash")}
                                    onChange={(event) => setExam(examStore.updateField(exam, 'hash', event.target.value))}
                                    label={translate('exam.hash')}
                                    value={exam.hash ?? ""}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%'}}>
                                    <InputLabel>{translate('exam.diagnosticDoctor')}</InputLabel>
                                        <Select
                                            sx={{ width: '100%'}}
                                            value={exam.diagnostic?.id.toString() ?? ""}
                                            error={examStore.hasError("diagnostic")}
                                            label={translate('exam.diagnosticDoctor')}
                                            onChange={(event: SelectChangeEvent) => setExam(examStore.updateField(exam, 'diagnostic', Object.assign({}, doctorStore.models.find(d => d.id === parseInt(event.target.value)))))}
                                        >
                                            {
                                                doctorStore?.models &&
                                                    doctorStore.models.map((d) => (
                                                        <MenuItem key={`DiagnosticMenuItem_${d.id}`} value={d.id}>{d.firstname} {d.lastname}</MenuItem>
                                                    ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%'}}>
                                        <InputLabel>{translate('exam.prescriberDoctor')}</InputLabel>
                                        <Select
                                            sx={{ width: '100%'}}
                                            value={exam.prescriber?.id.toString() ?? ""}
                                            error={examStore.hasError("prescriber")}
                                            label={translate('exam.prescriberDoctor')}
                                            onChange={(event: SelectChangeEvent) => setExam(examStore.updateField(exam, 'prescriber', Object.assign({}, doctorStore.models.find(d => d.id === parseInt(event.target.value)))))}
                                        >
                                            {
                                                doctorStore?.models &&
                                                    doctorStore.models.map((d) => (
                                                        <MenuItem key={`PrescriberMenuItem_${d.id}`} value={d.id}>{d.firstname} {d.lastname}</MenuItem>
                                                    ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%'}}>
                                        <InputLabel>{translate('exam.responsibleDoctor')}</InputLabel>
                                        <Select
                                            sx={{ width: '100%'}}
                                            error={examStore.hasError("responsible")}
                                            value={exam.responsible?.id.toString() ?? ""}
                                            label={translate('exam.responsibleDoctor')}
                                            onChange={(event: SelectChangeEvent) => setExam(examStore.updateField(exam, 'responsible', Object.assign({}, doctorStore.models.find(d => d.id === parseInt(event.target.value)))))}
                                        >
                                            {
                                                doctorStore?.models &&
                                                    doctorStore.models.map((d) => (
                                                        <MenuItem key={`ResponsibleMenuItem_${d.id}`} value={d.id}>{d.firstname} {d.lastname}</MenuItem>
                                                    ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                <FormControl sx={{ width: '100%'}}>
                                    <InputLabel>{translate('exam.patient')}</InputLabel>
                                        <Select
                                            sx={{ width: '100%'}}
                                            error={examStore.hasError("patient")}
                                            value={exam.patient?.id.toString() ?? ""}
                                            label={translate('exam.patient')}
                                            onChange={(event: SelectChangeEvent) => setExam(examStore.updateField(exam, 'patient', Object.assign({}, patientStore.models.find(d => d.id === parseInt(event.target.value)))))}
                                        >
                                            {
                                                patientStore?.models &&
                                                    patientStore.models.map((x) => (
                                                        <MenuItem key={`PatientMenuItem_${x.id}`} value={x.id}>{x.ipp} {x.firstname} {x.lastname}</MenuItem>
                                                    ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                <TextField
                                    sx={{ width: '100%'}}
                                    error={examStore.hasError("indication")}
                                    helperText={examStore.getError("indication")}
                                    onChange={(event) => setExam(examStore.updateField(exam, 'indication', event.target.value))}
                                    label={translate('exam.indication')}
                                    value={exam.indication}
                                    />
                                </Grid>
                                <Grid item xs={6}></Grid>
                                <Grid item xs={6}>
                                <FormGroup>
                                    <FormControlLabel 
                                        control={
                                            <Checkbox 
                                                checked={exam.readed ?? false} 
                                                onChange={(event) => setExam(examStore.updateField(exam, 'readed', event.target.checked))}
                                            />
                                        } 
                                        label={translate('exam.readed')} />
                                    <FormControlLabel control={
                                        <Checkbox 
                                            checked={exam.understood ?? false} 
                                            onChange={(event) => setExam(examStore.updateField(exam, 'understood', event.target.checked))}
                                        />} label={translate('exam.understood')} />
                                </FormGroup>
                                </Grid>
                            </Grid>
                            </Accordion>
                            
                            <Accordion sx={{pl: 2, pr: 2}} defaultExpanded={true}>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                    <Typography>{translate('exam.report')}</Typography>
                                </AccordionSummary>
                                <Conclusions exam={exam} conclusionList={exam.texts as ConclusionList} examType={exam.type ?? ''} withSection={false} onConclusionListChanged={(conclusionListModel: ConclusionList) => {
                                    setExam(examStore.updateField(exam, 'texts', conclusionListModel));
                                }} />
                            </Accordion>
                            
                            <Accordion sx={{pl: 2, pr: 2, pb: 2, flex: 1, marginBottom: 2 }} defaultExpanded={false}>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                    <Typography>QR Code</Typography>
                                </AccordionSummary>
                               
                                <Container sx={{display: "flex" }} >
                                    <ExamQrCode />
                                {/* <Card sx={{ width: "25%", ml: 10, backgroundColor: "black", position: "relative", mb: 2 }}>
                                    <CardContent sx={{height: 180}}>
                                        <Image
                                            src={getImage()}
                                            fit="scale-down"
                                        />
                                        <EditButton onChange={uploadImage} />
                                    </CardContent>
                                </Card> */}
                                </Container>
                            </Accordion>

                            <Container sx={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'space-between' }}>
                                <Button onClick={saveExam} variant="contained" sx={{ marginBottom: 2 }}  endIcon={<SaveIcon />}>
                                    Save
                                </Button>

                                <Button variant="outlined" onClick={() => navigate("/exams")} startIcon={<CancelIcon />}>
                                    Cancel
                                </Button>
                                
                            </Container>
                        </Box>
                        )
                }
                
        </Box>
    )
});