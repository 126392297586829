import { Instance, SnapshotOut, types } from "mobx-state-tree"

export const ValidationFieldErrorModel = types.model("ValidationFieldErrorModel").props({
    field: types.string,
    message: types.string
});

export const ValidationErrorModel = types.model("ValidationErrorModel").props({
    errors: types.optional(types.array(ValidationFieldErrorModel), []),
    message: types.string
});

type ValidationErrorType = Instance<typeof ValidationErrorModel>
type ValidationErrorFieldType = Instance<typeof ValidationFieldErrorModel>
export interface ValidationError extends ValidationErrorType {}
export interface ValidationFieldError extends ValidationErrorFieldType {}

type ValidationErrorSnapshotType = SnapshotOut<typeof ValidationErrorModel>
export interface ValidationErrorSnapshot extends ValidationErrorSnapshotType {}
export const createValidationErrorDefaultModel = () => types.optional(ValidationErrorModel, {} as ValidationErrorType)