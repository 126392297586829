import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, Box, IconButton, Button } from "@mui/material";
import { EditModal, IEditableColumn } from "../modals/EditModal";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { DeleteModal, IDeleteActions } from "../modals/DeleteModal";
import { useState } from "react";
import { ModelInstanceType, _NotCustomized } from "mobx-state-tree";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { ICrudActions } from "src/models/CrudStore";

export interface IColumn<T> {
    field: string;
    headerName: string;
    width: number|string;
    getValue: (data: T) => string|JSX.Element;
}

interface IActionsColumn 
{
    width?: number;
}

interface ITableProps<T> {
    columns: IColumn<T>[];
    data: T[];
    id: (data: T) => string;
    addEditModal?: IEditModalProps<T>|null|undefined;
    deleteModal?: IDeleteModalProps<T>|null|undefined;
    addButton?: IAddButtonInfo<T>;
    editButton?: IAddButtonInfo<T>;
    actions?: IActionsColumn;
}

interface IAddButtonInfo<T> {
    isVisible?: boolean;
    text?: string;
    redirectLink?: (model: T) => string;
}

interface IDeleteModalProps<ModelType> {
    store: ModelInstanceType<any, IDeleteActions<ModelType>>;
    title: (model: ModelType) => string;
}

interface IEditModalProps<ModelType> {
    store: ModelInstanceType<any, ICrudActions<ModelType>>;
    columns: IEditableColumn[]
}

export const CustomTable = observer(function<T>(props: ITableProps<T>) {

    const actionsProps = props.actions ? props.actions : {}
    const navigate = useNavigate();
    const [modelToEdit, setModelToEdit] = useState<T|null>(null);
    const [modelToDelete, setModelToDelete] = useState<T|null>(null);
    const displayActionsColumns = props.addEditModal || props.deleteModal  || props.addButton || props.editButton ? true : false;

    return (
        <Box className="page">
            {
                props.addButton && (
                    <Button sx={{ mb: 2 }} onClick={async() => {
                        props.addEditModal && setModelToEdit({} as T);
                        props.addButton?.redirectLink && navigate(props.addButton.redirectLink({} as T))
                    }} variant="outlined" endIcon={ <AddIcon />}>
                       { props.addButton.text }
                    </Button>
                )
            }

            {
                props.addEditModal && <EditModal {...props.addEditModal} model={modelToEdit} setModel={setModelToEdit} ></EditModal>
            }

            {
                props.deleteModal && <DeleteModal {...props.deleteModal} model={modelToDelete} setModel={setModelToDelete} ></DeleteModal>
            }
            
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {
                                props.columns.map((column: IColumn<T>) => (
                                    <TableCell key={column.field}>{column.headerName}</TableCell>
                                ))
                            }
                            {
                                displayActionsColumns && <TableCell  key="actions">Actions</TableCell>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {
                        props.data && props.data.map((data: T, index) => (
                            <TableRow
                            key={`TableRow_${index}_${props.id(data)}`}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                {
                                    props.columns.map((column: IColumn<T>) => (
                                        <TableCell 
                                            key={`${props.id(data)}_${column.field}`} sx={{ width: column.width}}>{column.getValue(data)}</TableCell>
                                    ))
                                }

                                {
                                    displayActionsColumns && (
                                        <TableCell { ...actionsProps } key={`${props.id(data)}_Actions`}>
                                        {
                                            (props.addEditModal || props.editButton) && (
                                                <IconButton onClick={async() => {
                                                    props.addEditModal && setModelToEdit(data)
                                                    props.editButton?.redirectLink && navigate(props.editButton.redirectLink(data))
                                                }}>
                                                    <EditIcon />
                                                </IconButton>
                                            )
                                        }
                                        {
                                            props.deleteModal && (
                                                <IconButton onClick={async() => {
                                                    setModelToDelete(data);
                                                }}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            )
                                        }
                                        </TableCell>
                                    )
                                }
                                
                            </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
})