import { Instance, SnapshotOut, types } from "mobx-state-tree"

export const AuthUserModel = types.model("User").props({
  id: types.identifierNumber,
  name: types.maybe(types.string),
  email: types.maybe(types.string),
  phone_number: types.maybeNull(types.string),
  type: types.maybe(types.string),
  auth_type: types.maybe(types.string),
})

export const AuthModel = types.model("Auth").props({
  userId: types.maybe(types.integer),
  accessToken: types.maybe(types.string),
  refreshToken: types.maybe(types.string),
  expiresInSeconds: types.optional(types.maybeNull(types.number), null),
  expiresAt: types.optional(types.maybeNull(types.string), null),
  user: types.optional(types.maybeNull(AuthUserModel), null),
})

type AuthType = Instance<typeof AuthModel>
export interface Auth extends AuthType {}
type AuthSnapshotType = SnapshotOut<typeof AuthModel>
export interface AuthSnapshot extends AuthSnapshotType {}
export const createAuthDefaultModel = () => types.optional(AuthModel, {})
