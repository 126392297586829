import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../models";
import { Box, Backdrop, CircularProgress } from "@mui/material";

import { useParams } from 'react-router-dom';
import { translate } from "../../i18n";
import { UserForm } from "src/components/users/UserForm";
import { buildDto } from "src/models/user/user";

export const UserEdit = observer(() => {
    const { userStore } = useStores();
    const params = useParams();
    const userId: string = params.userId ?? "";

    useEffect(() => {

        if(!userStore || !userId || userStore.loading) {
            return;
        }

        (async() => {
            await userStore.get(userId);
        })();
    }, [userStore, userId]);

    return (
        <Box className="page" sx={{
            marginTop: 8,
            
          }}>
                {
                    userStore.loading &&
                    (
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={true}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    )
                }
                {
                    userStore.currentModel && (
                        <UserForm userFields={buildDto(userStore.currentModel)} title={translate('user.edit') + ' ' + userStore.currentModel.name ?? ''} />
                    )
                }
                
        </Box>
    )
});