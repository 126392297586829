
export function __set(obj: any, path: string, value: any) : any {
    let copy = {...obj};
    let schema = copy;  // a moving reference to internal objects within obj
    const pList = path.split('.');
    const len = pList.length;

    for (let i = 0; i < len - 1; i++) {
        const elem = pList[i];

        if (!schema[elem]) {
            schema[elem] = {};
        }

        schema = schema[elem];
    }

    schema[pList[len - 1]] = value;

    return copy
}

export function __get(obj: any, path: string): any {
    let schema = obj;  // a moving reference to internal objects within obj
    const pList = path.split('.');
    const len = pList.length;
    
    for (let i = 0; i < len - 1; i++) {
        const elem = pList[i];

        if (!schema[elem]) {
            return null;
        }
        
        schema = schema[elem];
    }

    return schema[pList[len - 1]];
}