import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { ConclusionModel } from "./conclusion"

/**
 * ConclusionListn model.
 */
export const ConclusionListModel = types.model("ConclusionList").props({
    scientist: types.maybeNull(ConclusionModel),
    // detailed: types.maybeNull(ConclusionModel),
    simple: types.maybeNull(ConclusionModel)
})

type ConclusionListType = Instance<typeof ConclusionListModel>
export interface ConclusionList extends ConclusionListType {}
type ConclusionListnSnapshotType = SnapshotOut<typeof ConclusionListModel>
export interface ConclusionListnSnapshot extends ConclusionListnSnapshotType {}
export const createExamDefaultModel = () => types.optional(ConclusionListModel, {} as ConclusionListType)