import { TextSimplification } from "src/models/text-simplifications/text-simplification";
import { Api, CrudRouteConfig, ICrudConfig } from "./api"
import { ApiCrudService } from "./api-crud-service"
import FileDownload from 'js-file-download';

class TextSimplificationConfig implements ICrudConfig<TextSimplification> 
{
  get = (id: number|string) => new CrudRouteConfig<TextSimplification>(`/admin/text-simplifications/${id}`);
  getAll = new CrudRouteConfig<TextSimplification>("/admin/text-simplifications");
}

export class TextSimplificationApi extends ApiCrudService<TextSimplification> {
  constructor(api: Api) {
    super(api, new TextSimplificationConfig)
  }

  async export() {
    const response = await this.api.exportTextSimplifications()

    if(!response.ok) 
    {
      throw new Error("An error occured");
    }

    const filename = "text-simplifications.xlsx";
    
    FileDownload(response.data, filename);
  }
}
