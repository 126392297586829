import { FormControl, IconButton, InputAdornment, TextField, TextFieldProps } from "@mui/material"
import { ValidationFieldError } from "../../models/error/error-validation";
import { IControlledErrorStore } from "src/models/CrudStore";
import { observer } from "mobx-react";
import { __get, __set } from "src/utils/objects";
import { useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

interface IParams<TModel, TItem>
{
    textFieldProps?: TextFieldProps;
    store: IControlledErrorStore<TModel>;
    errors: ValidationFieldError[] | null;
    text: string;
    // value: string;
    item: TItem;
    fieldName: string;
    onChange: (updatedItem: TItem) => void;
}

export const ControlledText = observer(function<TModel, TItem>(props: IParams<TModel, TItem>) {

    const textFieldProps = props.textFieldProps ? props.textFieldProps : {};
    
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    const { hasError, getError, updateField } = props.store;
    const value = __get(props.item, props.fieldName) ?? "";

    const otherProps = textFieldProps.type === "password" ? {
        InputProps: {
            endAdornment: (
                <InputAdornment position="end">
                <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
                </InputAdornment>
            )
        }
    } : {}

    return (
        <FormControl sx={{ width: '100%'}}>
            <TextField
                value={value}
                sx={{ width: '100%'}}
                error={hasError(props.fieldName)}
                label={hasError(props.fieldName) ? getError(props.fieldName) : props.text}
                onChange={(event) => props.onChange(__set(props.item, props.fieldName, event.target.value))}
                required={true}
                {...textFieldProps}
                {...otherProps}
                type={showPassword || textFieldProps.type !== "password" ? "text" : "password"}
            />
            </FormControl>
    )
})