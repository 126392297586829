import { types } from "mobx-state-tree"
import { Exam, ExamModel, ExamSnapshot } from "./exam"
import { ExamApi } from "../../services/api/exam-api"
import { FileApi } from "../../services/api/file-api"
import { withEnvironment } from "../extensions/with-environment"
import { ParagraphType } from "../sentence/paragraph"
import { ConclusionList } from "../conclusions/conclusion-list"
import { Conclusion } from "../conclusions/conclusion"
import { Lang } from "../lang/lang"
import { SimplifiedTextType } from "src/services/api"
import { BuildCrudModel } from "../CrudStore"
import { ICrudApi } from "src/services/api/api-crud-service"

const CrudModel = BuildCrudModel<Exam>(ExamModel, {
  textCreateSuccess: () => "Exam created successfully",
  textUpdateSuccess: () => "Exam updated successfully"
})
.actions((self) => ({
  getApi: (): ICrudApi<Exam>|null => new ExamApi(self.environment.api),
}));

/**
 * store containing Exams
 */
const ExamStoreModel = types
  .model("ExamStore")
  .props({
    scanExamId: types.optional(types.maybeNull(types.number), null)
  })
  .extend(withEnvironment)
  .actions((self) => ({
    getStatus: (exam: Exam) => {
      if(exam.understood && exam.readed) {
        return 'understood';
      }

      if(!exam.readed) {
        return 'unread';
      }

      return 'not_understood';
    },
    saveScanExamId(examSnapshots: ExamSnapshot|null) {
      self.scanExamId = examSnapshots?.id ?? null;
    }
  }))
  .actions((self) => ({

    createParagrah(id: number, translationId: number, sectionId: number, lang: Lang) {
      const newParagraph: ParagraphType = {
        lang: lang.code,
        lang_id: lang.id,
        title: 'New paragraph',
        sentences: "",
        section_id: sectionId,
        translation_id: translationId,
        id
      } as ParagraphType;

      return newParagraph;
    },

    async uploadImage(ex: Exam, image: File): Promise<Exam|null> {
      const api = new ExamApi(self.environment.api)

      const result = await  api.uploadImage(ex.id, image);
      if(result.kind === 'ok') {
        return {
          ...ex,
          image_url: result.payload.url
        } as Exam;
      }

      return null;
    },
  }))
  .views(() => {
    return {
      getExamScientistTextInLang(exam: Exam, languageCode: string) {
        const sentences = exam.texts?.scientist?.paragraphs.filter(p => p?.lang == languageCode).map(p => p?.sentences) ?? []

        return sentences.join(' ')
      }
    }})

export const CrudExamStoreModel = types.compose(CrudModel, ExamStoreModel).actions(
  (self) => ({

    async updateConclusionsFromFile(ex: Exam, provider: string, file: File, lang: string,  selectedContextType: string, activateTextSimplification: boolean) {
      self.setLoading(true);

      const api = new ExamApi(self.environment.api);
      const result = await api.uploadTextsFromFile(ex.id, provider, file, lang, selectedContextType, activateTextSimplification)

      self.setLoading(false);

      if (result.kind !== 'ok') {

        if(result.kind === "rejected") {
          self.environment.messageStore.error(
            result.error?.message ?? ''
          )
        }

        throw new Error(result.kind);
      }

      self.save({
        ...ex,
        ...{
          texts: result.payload.texts
        }
      });
      
    },

    async translateExamTexts(ex: Exam, provider: string, lang: string) 
    {
      self.setLoading(true);

      const api = new ExamApi(self.environment.api);

      const result = await api.translateExamTexts(ex, provider, lang);

      self.setLoading(false);

      if (result.kind !== 'ok') {

        if(result.kind === "rejected") {

          self.setLoading(false);

          self.environment.messageStore.error(
            result.error?.message ?? ''
          )
        }

        throw new Error(result.kind);
      }

      self.save({
        ...ex,
        ...{
          texts: result.payload as ConclusionList
        }
      });
    },

    async simplifyTexts(ex: Exam, scientistText: string, model: string, contextType: string, lang: string, lang_id: number) 
    {
      self.setLoading(true);

      const api = new ExamApi(self.environment.api);

      const result = await api.simplifyExamTexts(ex, scientistText, model, contextType, lang);

      self.setLoading(false);

      if (result.kind !== 'ok') {

        if(result.kind === "rejected") {

          self.environment.messageStore.error(
            result.error?.message ?? ''
          )
        }

        throw new Error(result.kind);
      }

      const text = result.payload as SimplifiedTextType;

      if(text == null) {
        return;
      }

      const sentence: string = text.text

      const conclusion = {
          level: ex.texts?.simple?.level ?? 'simple',
          paragraphs: [
            {
              id: -1,
              lang: lang,  
              section_id: null,
              lang_id: lang_id,
              sentences: sentence
            } as ParagraphType
          ] as ParagraphType[]
      } as Conclusion;

      const texts: ConclusionList = {
        ...ex.texts,
        ...{
          simple: conclusion
        }
      } as ConclusionList;

      self.save({
        ...ex,
        ...{
          texts
        }
      });
    },

    async getFileText(file: File): Promise<string|null> {
      self.setLoading(true);

      const api = new FileApi(self.environment.api);
      const result = await api.get(file)
      
      self.setLoading(false);

      if (result.kind !== 'ok') {

        if(result.kind === "rejected") {

          self.environment.messageStore.error(
            result.error?.message ?? ''
          )
        }
        
        throw new Error(result.kind);
      }

      return result.payload as string
    },
  }))
