import { Instance, SnapshotOut, types } from "mobx-state-tree"

const NestedUserModelProps = types.model("NestedUserModelProps").props({
  id: types.maybe(types.number),
  lastname: types.maybe(types.maybeNull(types.string)),
  firstname: types.maybe(types.maybeNull(types.string)),
  user_id: types.maybe(types.maybeNull(types.number)),
  client_id: types.maybe(types.maybeNull(types.number))
})

export const UserModel = types.model("User").props({
  id: types.identifierNumber,
  name: types.maybe(types.string),
  email: types.maybe(types.string),
  phone_number: types.maybeNull(types.string),
  type: types.maybe(types.string),
  auth_type: types.maybe(types.string),
  password: types.maybe(types.string),
  related: types.maybe(
    NestedUserModelProps
  )
})

export type UserType = Instance<typeof UserModel>
export interface User extends UserType {}
type UserSnapshotType = SnapshotOut<typeof UserModel>
export interface UserSnapshot extends UserSnapshotType {}
export const createUserDefaultModel = () => types.optional(UserModel, {} as UserType)

export type NestedUserType = Instance<typeof NestedUserModelProps>
interface NestedUserDto extends NestedUserType {}

export interface UserDto extends User {
  related: NestedUserDto | undefined
}

export const buildDto = (user: User|null): UserDto => {
  return user ? {...user, ...{related: {...user.related}}} as UserDto : {
      id: -1,
      name: "",
      email: "",
      phone_number: ""
  } as UserDto
}
