import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../models";
import { Box, Backdrop, CircularProgress } from "@mui/material";
import { translate } from "../../i18n";
import { UserForm } from "src/components/users/UserForm";
import { User, UserType, buildDto } from "src/models/user/user";

export const UserCreate = observer(() => {
    return (
        <Box className="page" sx={{
            marginTop: 8,
          }}>
            <UserForm 
                    userFields={buildDto(null)}
                    title={translate('user.create') ?? ""} />
        </Box>
    )
});