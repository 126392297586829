import { Monitor } from "apisauce"
import { Api } from "../services/api"
import { MessageStore, MessageStoreModel } from "./message/message-store";

/**
 * The environment is a place where services and shared dependencies between
 * models live.  They are made available to every model via dependency injection.
 */
export class Environment {

  messageStore: MessageStore;

  constructor() {
    this.api = new Api()

    this.messageStore = MessageStoreModel.create();
  }

  setAccessToken(accessToken: string|null) {
    this.api.setAccessToken(accessToken)
  }

  addMonitor(monitor: Monitor) {
    this.api.addMonitor(monitor)
  }
 
  async setup() {
    await this.api.setup()
  }

  /**
   * Our api.
   */
  api: Api
}
