import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Exam } from "../../models/exam/exam";
import { useStores } from "../../models";
import { Box, Backdrop, CircularProgress } from "@mui/material";

import { useParams } from 'react-router-dom';
import { translate } from "../../i18n";
import { ExamForm } from "../../components/exams/ExamForm";

export const ExamEdit = observer(() => {
    const { examStore } = useStores();
    const [exam, setExam] = useState<Exam|null>(null);
    const params = useParams();
    const examId: string = params.examId ?? '';

    const refreshExam = (exam: Exam) => {
        setExam(exam)
    }

    useEffect(() => {
        (async() => {
            await examStore.get(examId);

            setExam(examStore.currentModel);
        })();
    }, [examStore, setExam, examId]); 

    return (
        <Box className="page" sx={{
            marginTop: 8,
            
          }}>
                {
                    examStore.loading &&
                    (
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={true}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    )
                }
                {
                    exam && (
                        <ExamForm redirectAfterSave={false} exam={exam} title={translate('exam.edit') + ' ' + exam.exam_id ?? ''} setExam={refreshExam} />
                    )
                }
                
        </Box>
    )
});