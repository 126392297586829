import { observer } from "mobx-react";
import { useEffect } from "react";
import { useStores } from "../models";

export const AuthGuard = observer(() => {
    const { authStore } = useStores();
    useEffect(() => {
        if(!authStore.payload?.accessToken && window.location.pathname !== "/login") {
            window.location.replace("/login");
        }
    }, [authStore.payload?.accessToken])
    return null;
})