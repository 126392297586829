// Use this import if you want to use "env.js" file
// const { API_URL } = require("../../config/env")
// Or just specify it directly like this:
import config from '../../config/env'

const API_URL = config.API_URL ||  "http://example.com"

/**
 * The options used to configure the API.
 */
export interface ApiConfig {
  /**
   * The URL of the api.
   */
  url: string

  /**
   * Milliseconds before we timeout the request.
   */
  timeout: number,

  client_id: string | undefined,
  client_secret: string | undefined
}

/**
 * The default configuration for the app.
 */
export const DEFAULT_API_CONFIG: ApiConfig = {
  url: API_URL || "https://jsonplaceholder.typicode.com",
  timeout: 1000 * 80,
  client_id: config.OAUTH_CLIENT_ID,
  client_secret: config.OAUTH_CLIENT_SECRET
}
