import { types } from "mobx-state-tree"
import { BuildCrudModel, IModelWithId } from "../CrudStore"
import { GetTModelResult, ICrudApi } from "src/services/api/api-crud-service";
import { SimplificationContextApi } from "src/services/api/simplification-context-api";
import { SimplificationContext, SimplificationContextModel } from "./simplification-context";
import { Api } from "src/services/api";

const SimplificationContextStoreModel = types
  .model("SimplificationContextStoreModel")

  const getApi = (api: Api) => new SimplificationContextApi(api)

  const CrudModel = BuildCrudModel(SimplificationContextModel)
  .actions((self) => ({
    getApi: (): ICrudApi<SimplificationContext>|null => getApi(self.environment.api),
    uploadJsonFile: async(file: File, callback:() => void) => {
      const fileReader = new FileReader();

      fileReader.readAsText(file, "UTF-8");
      fileReader.onerror = err => {
          console.error(err)
      }
      
      fileReader.onload = async e => {

        const target = e.target;
        const result = target?.result;

        if (!result) {
          throw new Error("Invalid json file");
        }

        await self.sendQuery<GetTModelResult<IModelWithId>>(
          {
              api: self.getApi(),
              performQuery: async(_: ICrudApi<IModelWithId>): Promise<GetTModelResult<IModelWithId>> => 
                await getApi(self.environment.api).uploadSimplificationContextFromJsonContent(result),
              reloadStore: (payload: SimplificationContext|undefined) => {
                self.save(payload ?? null);

                if (payload) 
                {
                  self.notifySuccess("Context successfully imported")
                }
                
                callback()
              }
          }
        )
      }
    }
  }));
  
  export const CrudSimplificationContextStoreModel = types.compose(CrudModel, SimplificationContextStoreModel)