import { Dialog, DialogContent, Box, Grid, Select, MenuItem, SelectChangeEvent, SvgIcon, Typography, Button, styled } from "@mui/material";
import Grid2 from '@mui/material/Unstable_Grid2';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import TranslateIcon from '@mui/icons-material/Translate';
import Image from 'mui-image';
import { Editor } from '@tinymce/tinymce-react';
import { Editor as TinyMCEEditor } from 'tinymce';
import { Paragraph } from "../../models/sentence/paragraph";
import { useCallback, useEffect, useRef, useState } from "react";
import { ConclusionList } from "../../models/conclusions/conclusion-list";
import { RecursiveKeyOf, translate } from "../../i18n";
import { ReactComponent as FrenchFlagIcon } from "../../images/flags/fr.svg";
import { ReactComponent as EnglishFlagIcon } from "../../images/flags/us.svg";
import { ReactComponent as SpanishFlagIcon } from "../../images/flags/es.svg";
import { Conclusion } from "src/models/conclusions/conclusion";
import { useStores } from "src/models";
import { observer } from "mobx-react";
import { TINYMCE_KEY } from "src/config/env";
import { Exam } from "../../models/exam/exam";
import { MedicalModel } from "src/models/medical-models/medical-model";
import { ContextType } from "src/models/context-types/context-type";
import { TranslationProvider } from "src/models/translations/translation-provider";
import { VisuallyHiddenInput } from "../VisuallyHiddenInput";


interface IConclusionsProps {
    examType: string;
    withSection: boolean;
    conclusionList: ConclusionList;
    exam: Exam;
    onConclusionListChanged: (conclusionList: ConclusionList) => void;
}

const FrCode = "fr-FR";
const EnCode = "en-US";
const EsCode = "es-ES";

type AllLangKeys = {
    [FrCode]: typeof FrCode,
    [EnCode]: typeof EnCode,
    [EsCode]: typeof EsCode
}
  
type LangKeyPath = RecursiveKeyOf<AllLangKeys>

interface ReportType {
    key: string;
    label: string|null;
    getConclusion: () => Conclusion;
    getParagraphs: () => Paragraph[];
    editor: React.MutableRefObject<TinyMCEEditor | null>
}

interface IAnimatedElementProps {
    text: string|null
}

interface ILocation {
    xOffset: number
    xLocation: number
    goRight: boolean
    isFadingOut: boolean
    isLogoVisible: boolean
    curtainWidth: number
}

function fadeEffect(element: HTMLElement, fadeOut: boolean, callback: any, speed: number = 0.1) {

    var fadeEffect = setInterval(function () {
        if (!element.style.opacity) {
            element.style.opacity = "1";
        }

        let total = parseFloat(element.style.opacity);

        if(fadeOut) {
            if (total > 0) {
                total -= speed;
                element.style.opacity = total.toString();
            } else {
                callback();
                clearInterval(fadeEffect);
            }
        } else {
            if (total < 1) {
                total += speed;
                element.style.opacity = total.toString();
            } else {
                callback();
                clearInterval(fadeEffect);
            }
        }
        
    }, 200);
}

function fadeOutFadeInEffet(element: HTMLElement, callback: any, speed: number) {
    return fadeEffect(element, true, () => {
        return fadeEffect(element, false, callback, speed);
    }, speed);
}

interface ITransition {
    text: string
    times: number
}

interface ITransitionCounter {
    index: number
    displayedNbTimes: number
}

var timeOutId: number | null = null;

const AnimatedElement = (props: IAnimatedElementProps) => {

    const scanElementContainerRef = useRef<HTMLDivElement|null>(null);
    const imageContainerRef = useRef<HTMLDivElement|null>(null);
    const logoRef = useRef<HTMLElement|null>(null);

    const defaultLocation: ILocation =  { 
        xLocation: 0,
        xOffset: 0, 
        goRight: true, 
        isFadingOut: false, 
        isLogoVisible: true, 
        curtainWidth: 0 
    };

    const defaultCounter: ITransitionCounter = { index: 0, displayedNbTimes: 1 };

    const [blocLocation, setBlocLocation] = useState<ILocation>(defaultLocation)
    const [transitionCounter, setTransitionCounter] = useState<ITransitionCounter>(defaultCounter)

    const resetSequence = () => {
        if(timeOutId !== null) {
            window.clearInterval(timeOutId)
        }

        timeOutId = null
        setTransitionCounter(defaultCounter)
        setBlocLocation(defaultLocation)
    }

    const style = `
        <style>
        .container-sequence h1, .container-sequence  .center {
            text-align: center;
        }
        .container-sequence .icon {
            width: 60px;
        }
        .container-sequence img {
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 120px;
        }
        .container-sequence .main-color {
            color: #f57d3d;
        }
        </style>
    `;

    const transitions: ITransition[] = [
        {
            text: props.text ?? "",
            times: 1
        },
        {
            text: `${style}
                <div class="container-sequence">
                    <div class="container-sequence center main-color">
                        <svg class="icon" xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="24"><path d="m612-550 141-142-28-28-113 113-57-57-28 29 85 85ZM120-160v-80h480v80H120Zm520-280q-83 0-141.5-58.5T440-640q0-83 58.5-141.5T640-840q83 0 141.5 58.5T840-640q0 83-58.5 141.5T640-440Zm-520-40v-80h252q7 22 16 42t22 38H120Zm0 160v-80h376q23 14 49 23.5t55 13.5v43H120Z"/></svg>
                        <h1>We are analyzing your text...</h1>
                    </div>
                </div>
            `,
            times: 1
        },
        {
            text:  `${style}
                    <div class="container-sequence">
                        <div class="container-sequence center main-color">
                            <img src="/images/robot_owl.webp" />
                            <h1>We are working on the simplification...</h1>
                        </div>
                    </div>`,
            times: 1
        },
        {
            text:  `${style}
                    <div class="container-sequence">
                        <div class="center main-color">
                            <img src="/images/robot_owl.webp" />
                            <h1>Your text is currently beeing simplified...</h1>
                        </div>
                    </div>`,
            times: 1
        },
        {
            text:  `${style}
                    <div class="container-sequence">
                        <div class="container-sequence center main-color">
                            <svg class="icon" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m612-292 56-56-148-148v-184h-80v216l172 172ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-400Zm0 320q133 0 226.5-93.5T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 133 93.5 226.5T480-160Z"/></svg>
                            <h1>Should not be long anymore, please wait a moment...</h1>
                        </div>
                    </div>`,
            times: -1
        }
    ];

    const allNeededReferencesInitialized: any = (): boolean => scanElementContainerRef.current && imageContainerRef.current && logoRef.current ? true : false;

    const startSequence = () => {
        if (timeOutId || !allNeededReferencesInitialized()) {
            return;
        }

        if(!scanElementContainerRef.current || !imageContainerRef.current || !logoRef.current) {
            return;
        }

        const element: HTMLElement = scanElementContainerRef.current
        const container: HTMLElement = imageContainerRef.current
        const logo: HTMLElement = logoRef.current
        
        timeOutId = window.setInterval(() => {
            
            setBlocLocation((prevLocation: ILocation) => {

                if(prevLocation.isFadingOut) {
                    return prevLocation;
                }

                const curLocation: ILocation = { 
                    xOffset: prevLocation.xOffset, 
                    xLocation: element.getBoundingClientRect().x, 
                    goRight: prevLocation.goRight, 
                    isFadingOut: prevLocation.isFadingOut,
                    isLogoVisible: prevLocation.isLogoVisible,
                    curtainWidth: prevLocation.curtainWidth
                }
                
                let goRight = prevLocation.goRight
                let isLogoVisible = curLocation.isLogoVisible
                const speed: number = element.getBoundingClientRect().width * 2

                let offset: number = speed
                let isFadingOut = curLocation.isFadingOut;

                if (curLocation.goRight) {
                    // the element reached the max right location
                    if (!isFadingOut && curLocation.xLocation + element.getBoundingClientRect().width * 2 >= container.getBoundingClientRect().x + container.getBoundingClientRect().width) {
                        isFadingOut = true;
                        offset = -speed;
                        goRight = false;
                            
                        setTransitionCounter(curTransitionCounter => {
                            if(curTransitionCounter.displayedNbTimes < transitions[curTransitionCounter.index].times && transitions[curTransitionCounter.index].times > 0) {
                                return {
                                    index: curTransitionCounter.index,
                                    displayedNbTimes: curTransitionCounter.displayedNbTimes+1
                                }
                            }
                            else if (transitions.length > curTransitionCounter.index+1) {
                                return {
                                    index: curTransitionCounter.index+1,
                                    displayedNbTimes: 0
                                }
                            }

                            return curTransitionCounter
                        })
                        
                        return {
                            ...prevLocation,
                            ...{
                                isFadingOut: false,
                                offset,
                                goRight
                            }
                        }

                        // setBlocLocation(newPrevLocation => {
                        //     return {
                        //         ...newPrevLocation,
                        //         ...{
                        //             isFadingOut: false,
                        //             offset,
                        //             goRight
                        //         }
                        //     }
                        // })
                    }
                    // the element is on the left and goes right, the logo shall be displayed
                    // else if(curLocation.xOffset >= 0 && !prevLocation.isLogoVisible) {
                    //     isLogoVisible = true;
                    //     fadeOutFadeInEffet(logo, () => {
                    //         setBlocLocation(newPrevLocation => {
                    //             return {
                    //                 ...newPrevLocation,
                    //                 ...{
                    //                     isLogoVisible: true
                    //                 }
                    //             }
                    //         })
                    //     }, 0.3);
                    // }
                } else {
                    // the element reached the max left location
                    if(!isFadingOut && curLocation.xOffset <= -element.getBoundingClientRect().width) {
                        isFadingOut = true;
                        offset = speed;
                        goRight = true;

                        return {
                            ...prevLocation,
                            ...{
                                isFadingOut: false,
                                offset,
                                goRight
                            }
                        }

                    } else if(curLocation.xOffset <= logo.getBoundingClientRect().width && curLocation.isLogoVisible) {
                        isLogoVisible = false
                        offset = -speed;
                        goRight = false;
                        
                        fadeOutFadeInEffet(logo, () => {
                            setBlocLocation(newPrevLocation => {
                                return {
                                    ...newPrevLocation,
                                    ...{
                                        isLogoVisible: true
                                    }
                                }
                            })
                        }, 0.3);

                    }
                    else {

                        offset = -speed;
                        goRight = false;
                    }
                }
                
                return {
                    xLocation: curLocation.xLocation,
                    xOffset: curLocation.xOffset + offset,
                    goRight,
                    isFadingOut,
                    isLogoVisible,
                    curtainWidth: curLocation.xOffset + offset
                }
            })
                
        }, 100);
    }

    const setRef = (node: HTMLElement|HTMLDivElement, ref:  React.MutableRefObject<HTMLElement | null | HTMLDivElement>) => {
        if (ref.current && timeOutId) {
            resetSequence()
        }
        
        ref.current = node

        if(allNeededReferencesInitialized()) {
            startSequence()
        }
    }

    const setImageContainerRef = useCallback((node: HTMLDivElement) => {
        return setRef(node, imageContainerRef)
    }, [])

    const setLogoRef = useCallback((node: HTMLElement) => {
        return setRef(node, logoRef)
    }, [])

    const setScanElementContainerRef = useCallback((node: HTMLDivElement) => {
        return setRef(node, scanElementContainerRef)
    }, [])

    useEffect(() => {

        if(props.text && props.text.trim() !== '') {
            startSequence()
        } else if(!props.text || props.text.trim() === '') {
            resetSequence()
        }
    }, [props.text])

    return (
        <Dialog
        open={props.text ? true : false}
        fullWidth
        style={{ fontSize: 8, width: "100%", height: "100%" }}
        
        PaperProps={{
            sx: {
              height: "100%"
            }
          }}
        >
            <DialogContent ref={setImageContainerRef} style={{ height: "100%", width: "100%"}}>
                <Box 
                    ref={setLogoRef}
                    style={{ position: "absolute", left: 0, top: 0, width: "10%", zIndex: 2000 }}>
                    <Image  src="/images/logo-sheet-layer.png" />
                </Box>
                <Box
                    ref={setScanElementContainerRef}
                    style={{ left: blocLocation.xOffset + "px", top: 0, position: "absolute" }}
                    sx={{ width: 10, height: "100%", backgroundColor: "#f77b3a", zIndex: 2000 }}>
                </Box>
                {
                    transitionCounter.index == 0 && (
                        <Box 
                            style={{ backgroundColor: "white", height: "100%", width: blocLocation.curtainWidth, position: "absolute", left: 0, top: 0, zIndex: 1900, opacity: 0.6 }}>
                        </Box>
                    )
                }
                
                <Box style={{ height: "100%", width: "100%" }} dangerouslySetInnerHTML={{ __html: transitions[transitionCounter.index].text }}></Box>
            </DialogContent>
        </Dialog>
    )
}

export default observer(({ conclusionList, onConclusionListChanged, exam, examType, withSection }: IConclusionsProps) => {
    
    const reportTypes: ReportType[] = [
        {
            key: 'scientist',
            label: translate('reportLevels.scientist'),
            getConclusion: (): Conclusion => conclusionList?.scientist as Conclusion,
            getParagraphs: (): Paragraph[] => conclusionList?.scientist?.paragraphs as Paragraph[] ?? [] as Paragraph[],
            editor: useRef<TinyMCEEditor|null>(null)
        },
        // {
        //     key: 'detailed',
        //     label: translate('reportLevels.detailed'),
        //     getConclusion: (): Conclusion => conclusionList?.detailed as Conclusion,
        //     getParagraphs: (): Paragraph[] => conclusionList?.detailed?.paragraphs  as Paragraph[] ?? [] as Paragraph[]
        // },
        {
            key: 'simple',
            label: translate('reportLevels.simple'),
            getConclusion: (): Conclusion => conclusionList?.simple as Conclusion,
            getParagraphs: (): Paragraph[] => conclusionList?.simple?.paragraphs as Paragraph[] ?? [] as Paragraph[],
            editor: useRef<TinyMCEEditor|null>(null)
        }
    ];
    
    const [textToSimplifyContent, setTextToSimplifyContent] = useState<string|null>(null)
    const icons = {[FrCode]: FrenchFlagIcon, [EnCode]: EnglishFlagIcon, [EsCode]: SpanishFlagIcon};
    const [selectedLanguage, setSelectedLanguage] = useState<string|null>(null);
    const [selectedContextType, setSelectedContextType] = useState<ContextType|null>(null);
    const [selectedMedicalModel, setSelectedMedicalModel] = useState<MedicalModel|null>(null);
    const [selectedProvider, setSelectedProvider] = useState<TranslationProvider|null>(null);
    const { examStore, langStore, contextTypeStore, medicalModelStore, translationProviderStore } = useStores();
    
    const getIcon = (code: LangKeyPath) => icons[code];

    const [refSelectedFile, setRefSelectedFile] = useState<HTMLInputElement | undefined | null>();

    const updateParagraphs = (reportType: ReportType, paragraphs: Paragraph[], fields: any) => {
        const newParagraphs = reportType.getParagraphs().map(item => {

            let p = paragraphs.find(x => x.id === item.id);

            if(p) {
                return {
                    ...p,
                    ...fields
                }
            }

            return item;
        });

        const newConclusionList: ConclusionList = {
            ...conclusionList,
            [reportType.key]: {
                ...reportType.getConclusion(),
                paragraphs: newParagraphs.length ? newParagraphs : paragraphs.map(x => { return {...x, ...fields} })
            }
        } as ConclusionList;

        onConclusionListChanged(newConclusionList)
    };

    useEffect(() => {
        if(langStore?.langs.length && !selectedLanguage) {
            setSelectedLanguage(langStore.langs[0].code ?? "")
        }
    }, [selectedLanguage, setSelectedLanguage, langStore?.langs, langStore?.langs?.length])

    useEffect(() => {
        if(contextTypeStore?.contextTypes.length && !selectedContextType) {
            setSelectedContextType(contextTypeStore.contextTypes[0])
        }
    }, [selectedLanguage, setSelectedLanguage, langStore?.langs, langStore?.langs?.length])

    useEffect(() => {
        if(medicalModelStore?.medicalModels.length && !selectedMedicalModel) {
            setSelectedMedicalModel(medicalModelStore.medicalModels.find(x => x.is_default) ?? null)
        }
    }, [medicalModelStore?.medicalModels, medicalModelStore?.medicalModels?.length])

    useEffect(() => {
        if(translationProviderStore?.translationProviders?.length && !selectedProvider) {
            setSelectedProvider(translationProviderStore.translationProviders.find(x => x.is_default) ?? null)
        }
    }, [translationProviderStore?.translationProviders, translationProviderStore?.translationProviders?.length])

    const translateText = async() => {
        try {
            await examStore.translateExamTexts(exam, selectedProvider?.code ?? '', selectedLanguage ?? '');
        } catch(err) {
            console.error(err);
            return;
        }

        const newExam = examStore.models.find(e => e.id === exam.id);

        if(!newExam) {
            return;
        }

        onConclusionListChanged(newExam.texts as ConclusionList)
    }

    const simplifyText = async() => {
        
        try {
            const model: string|undefined|null = selectedMedicalModel?.name;
            const contextType: string = selectedContextType?.code ?? '';

            if(!model) {
                throw new Error("Please select a model");
            }

            if(contextType == '') {
                throw new Error("Please select a disease");
            }

            const currentLang = langStore.langs.find(x => x.code === selectedLanguage)

            if(!currentLang) {
                throw new Error("Please select a language");
            }

            const scientistText = examStore.getExamScientistTextInLang(exam, currentLang.code ?? '');

            setTextToSimplifyContent(scientistText)

            await examStore.simplifyTexts(
                exam, 
                scientistText, 
                model, 
                contextType, 
                currentLang.code ?? '', 
                currentLang.id
            );
        } catch(err) {
            console.error(err);
            return;
        } finally {
            setTextToSimplifyContent(null)
        }

        const newExam = examStore.models.find(e => e.id === exam.id);

        if(!newExam) {
            return;
        }

        onConclusionListChanged(newExam.texts as ConclusionList)
    }

    const importDocument = async(file: File) => {
        
        const scientistReportType = reportTypes.find(x => x.key == "scientist")

        if(!scientistReportType) {
            return;
        }

        scientistReportType.editor.current?.setContent('')

        var fileContent: string|null = null

        try { 
            fileContent = await examStore.getFileText(file)
        } catch(err) {
            console.error(err);
            return;
        }
        
        scientistReportType.editor.current?.setContent(fileContent ?? '')

        if(refSelectedFile) {
            // clear selection
            refSelectedFile.files = null;
            refSelectedFile.value = "";
        }
    }

    const reportTypeHasEmptyText = (reportTypeName: string): boolean => {

        const reportType = reportTypes.find(x => x.key == reportTypeName)

        const cleanText = (text: string) => {

            if(!text) {
                return '';
            }

            const newText = text.split(' ')
            .join('')
            .replace(/<[^>]*>/g, '')
            .split('&nbsp;')
            .join('')
            .trim()

            return newText;
        }

        const conclusion = reportType?.getConclusion()?.paragraphs.find(x => x?.lang == selectedLanguage);

        return conclusion == null || cleanText(conclusion.sentences ?? '') == '';
    }

    const isSimplifyButtonDisabled = reportTypeHasEmptyText("scientist")
    const isTranslateButtonDisabled = isSimplifyButtonDisabled && reportTypeHasEmptyText("simple")

    return (
        <div>
            <Box sx={{ m: 1, minWidth: 120, flex: 1, display: "flex", flexDirection: "row" }}>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Select
                            sx={{ width: '100%'}}
                            value={selectedProvider?.code ?? ''}
                            onChange={(event: SelectChangeEvent) => setSelectedProvider(translationProviderStore.translationProviders.find(x => x.code == event.target.value) ?? null)}
                            >
                                {
                                    translationProviderStore.translationProviders.map((provider) => (
                                        <MenuItem key={`MenuItem_${provider.code}`} value={provider.code}>{provider.name}</MenuItem>
                                    ))
                                }
                    </Select>
                    </Grid>
                    <Grid item xs={6}>
                        <Select
                            sx={{ width: '100%'}}
                            value={selectedContextType?.code ?? ''}
                            onChange={(event: SelectChangeEvent) => setSelectedContextType(contextTypeStore.contextTypes.find(x => x.code === event.target.value) ?? null)}
                            autoWidth
                        >
                            {
                                contextTypeStore?.contextTypes && contextTypeStore.contextTypes.map((contextType) => (
                                    <MenuItem key={`MenuItem_${contextType.id}`} value={contextType.code}>{contextType.description}</MenuItem>
                                ))
                            }
                        </Select>
                    </Grid>
                    <Grid item xs={6}>
                        <Select
                            sx={{ width: '100%'}}
                            value={selectedLanguage ?? ''}
                            onChange={(event: SelectChangeEvent) => setSelectedLanguage(event.target.value)}
                        >
                            {
                                langStore?.langs && langStore.langs.map((language) => (
                                    <MenuItem key={`MenuItem_${language.code}`} value={language.code}><SvgIcon sx={{mr: 2, verticalAlign: 'bottom'}} inheritViewBox component={getIcon(language.code as LangKeyPath)}/> {language.name}</MenuItem>
                                ))
                            }
                        </Select>
                    </Grid>
                    <Grid item xs={6}>
                        <Select
                            sx={{ width: '100%'}}
                            value={selectedMedicalModel?.code ?? ''}
                            onChange={(event: SelectChangeEvent) => setSelectedMedicalModel(medicalModelStore.medicalModels.find(x => x.code == event.target.value) ?? null)}
                            autoWidth
                        >
                            {
                                medicalModelStore?.medicalModels && medicalModelStore.medicalModels.map((model) => (
                                    <MenuItem key={`MenuItem_${model.id}`} value={model.code}>{model.name}</MenuItem>
                                ))
                            }
                        </Select>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid2 container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                            <Grid2 xs={4}>
                                <Button component="label" variant="contained" sx={{width: "100%"}} startIcon={<CloudUploadIcon />}>
                                    Import
                                    <VisuallyHiddenInput accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,application/pdf" ref={ elt => setRefSelectedFile(elt) } onChange={async(event) => {
                                        const file: File|null = event.target?.files?.length ? event.target.files[0] : null;

                                        if(!file) {
                                            return;
                                        }

                                        await importDocument(file);
                                    }} type="file" />
                                </Button>
                            </Grid2>
                            <Grid2 xs={4}>
                                <Button 
                                    disabled={isSimplifyButtonDisabled}
                                    onClick={simplifyText} component="label" variant="contained" sx={{width: "100%"}}  startIcon={<img style={{ maxHeight: "20px"}} src="/images/owl-no-right-wing.png" />}>
                                    Simplifier
                                </Button>
                            </Grid2>
                            <Grid2 xs={4}>
                                <Button
                                    disabled={isTranslateButtonDisabled}
                                    onClick={translateText} component="label" sx={{width: "100%"}}  variant="contained" startIcon={<TranslateIcon />}>
                                    Traduire
                                </Button>
                            </Grid2>
                        </Grid2>
                    </Grid>
                </Grid>
            </Box>
            
            <AnimatedElement text={textToSimplifyContent} />
            
                <Grid2 container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>

                {
                    reportTypes.map((reportType, index) =>  {

                        const item: Paragraph = reportType.getParagraphs().find(x => x.lang === selectedLanguage) ?? {
                            id: -index,
                            sentences: '',
                            section_id: null,
                            lang: selectedLanguage,
                            lang_id: langStore.langs.find(x => x.code == selectedLanguage)?.id ?? -1
                        }

                        return (
                            <Grid2 key={`Grid2_${index}_${selectedLanguage}`} xs={6}>
                                <Typography variant="h6">
                                    {reportType.label}
                                </Typography>
                                <Editor
                                    onInit={(evt, editor) => reportType.editor.current = editor}
                                    onEditorChange={(value) => {
                                        updateParagraphs(reportType, [item], { sentences: value })
                                    }}
                                    value={item.sentences ?? ""}
                                    apiKey={TINYMCE_KEY}
                                    init={{
                                    height: 500,
                                    menubar: false,
                                    forced_root_block: "p",
                                    plugins: [
                                        'anchor', 
                                        'autolink',
                                        'charmap', 
                                        'codesample',
                                        'emoticons', 
                                        'image', 
                                        'link',
                                        'lists', 
                                        'media',
                                        'searchreplace', 
                                        'table',
                                        'visualblocks', 
                                        'wordcount'
                                    ],
                                    toolbar: 'undo redo | formatselect | ' +
                                    'bold italic backcolor | alignleft aligncenter ' +
                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                    'removeformat | help',
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px } * { margin: 0px; padding: 0px; } h1 { margin-bottom: 12pt; } p { margin-bottom: 6pt; } '
                                    }}
                                />
                            </Grid2>
                        )
                    })
                }
                </Grid2>
        </div>
    );
});