import { cast, types } from "mobx-state-tree"
import { TextSimplificationApi } from "../../services/api/text-simplification-api"
import { BuildCrudModel } from "../CrudStore"
import { ICrudApi } from "src/services/api/api-crud-service";
import { Api } from "src/services/api";
import { withEnvironment } from "../extensions/with-environment";
import { NpsScore, NpsScoreModel } from "./nps-score";
import { ChampApi } from "src/services/api/champ/champ-api";

const getApi = (baseApi: Api) => new ChampApi(baseApi)

export const ChampStoreModel = types.model('ChampStoreModel')
  .extend(withEnvironment)
  .props(
    {
        value: types.optional(types.maybeNull(NpsScoreModel), null),
        loading: false,
    }
  )
  .actions((self) => ({
    setLoading: (value: boolean) => {
      self.loading = value
    },
    setValue: (value: NpsScore) => {
      self.value = cast(value)
    }
  }))
  .actions((self) => ({
    async export() {
      self.setLoading(true)
      
      try {
        await getApi(self.environment.api).exportFeedbacks()
      } catch (ex){
        self.setLoading(false)
        throw ex;
      }

      self.setLoading(false)
    },

    async getNps() {
      self.setLoading(true)
      
      try {
        const result = await getApi(self.environment.api).getNps()

        if (result.kind === "ok") {
          self.setValue(result.payload)
        }
        
      } catch (ex){
        self.setLoading(false)
        throw ex;
      }

      self.setLoading(false)
    }
  }));
  