import { Api } from "./api"
import { GetPatientsResult, GetPatientResult, DeleteModelResult } from "./api.types"
import { getGeneralApiProblem } from "./api-problem"
import { Patient } from "../../models/patient/patient"

export class PatientApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }
  
  async getAll() : Promise<GetPatientsResult> {
    try {
      const response = await this.api.getPatients()

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const payload = response.data

      return { kind: 'ok', payload }
    } catch (e) {
      return { kind: "bad-data" }
    }
  }

  async get(id: number|string) : Promise<GetPatientResult> {
    try {
      const response = await this.api.getPatient(id as number)

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const payload = response.data

      return { kind: 'ok', payload }
    } catch (e) {
      return { kind: "bad-data" }
    }
  }

  async createOrUpdate(patient: Patient) : Promise<GetPatientResult> {
    try {
      const response = patient.id ? await this.api.updatePatient(patient) : await this.api.createPatient(patient);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const payload = response.data

      return { kind: 'ok', payload }
    } catch (e) {
      return { kind: "bad-data" }
    }
  }

  async delete(patient: Patient) : Promise<DeleteModelResult> {
    try {
      const response = await this.api.deletePatient(patient);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: 'ok' }
    } catch (e) {
      return { kind: "bad-data" }
    }
  }
}
