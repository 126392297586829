/**
 * The app navigator (formerly "AppNavigator" and "MainNavigator") is used for the primary
 * navigation flows of your app.
 * Generally speaking, it will contain an auth flow (registration, login, forgot password)
 * and a "main" flow which the user will use once logged in.
 */
import {
  Navigate,
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';

import { observer } from "mobx-react-lite"
import { SignIn } from "../screens/SignIn"
import { ExamList } from "../screens/exams/ExamList";
import { ExamEdit } from "../screens/exams/ExamEdit";
import { PatientList } from "../screens/patients/PatientList";
import { DoctorList } from "../screens/doctors/DoctorList";
import { ReportTypeList } from "../screens/report-types/ReportTypeList";
import { Alert, AlertColor, Box, Snackbar } from "@mui/material";
import { useStores } from "src/models";
import { ExamCreate } from "../screens/exams/ExamCreate";
import { AuthGuard } from "src/guards/AuthGuard";
import { Home } from "../screens/Home";
import { UsertList } from 'src/screens/users/UserList';
import { UserEdit } from 'src/screens/users/UserEdit';
import { UserCreate } from 'src/screens/users/UserCreate';
import { TextSimplificationList } from 'src/screens/text-simplifications/TextSimplificationList';
import { SimplificationContextList } from 'src/screens/simplification-context/SimplificationContextList';
import { SimplificationContextEdit } from 'src/screens/simplification-context/SimplificationContextEdit';
import { Error4O4 } from 'src/screens/errors/404';
import { NpsScreen } from 'src/screens/champ/NpsScreen';

/**
 * This type allows TypeScript to know what routes are defined in this navigator
 * as well as what properties (if any) they might take when navigating to them.
 *
 * If no params are allowed, pass through `undefined`. Generally speaking, we
 * recommend using your MobX-State-Tree store(s) to keep application state
 * rather than passing state through navigation params.
 *
 * For more information, see this documentation:
 *   https://reactnavigation.org/docs/params/
 *   https://reactnavigation.org/docs/typescript#type-checking-the-navigator
 */
export type NavigatorParamList = {
  landing: undefined
  demo: undefined
  demoList: undefined,
  login: undefined,
  menu: undefined,
  scanCode: undefined,
  examRender: { id: number },
  examComment: { id: number },
  examList: undefined,
  settings: { from: keyof NavigatorParamList, params: any },
  validationOtp: undefined,
  updatePassword: undefined
  // 🔥 Your screens go here
}

// Documentation: https://reactnavigation.org/docs/stack-navigator/

interface IPrivateRouteParams 
{
  component: any;
  roles?: string[]
}

const PrivateRoute = ({ component, roles }: IPrivateRouteParams) => {

  const { authStore } = useStores();
  
  // Add your own authentication on the below line.
  const isAuthenticated = authStore.payload?.accessToken ? true : false;

  if(isAuthenticated && !authStore.payload?.user?.type) 
  {
    authStore.clear()
  }

  return (
    isAuthenticated && (!roles || roles.includes(authStore.payload?.user?.type ?? "")) ? (
      component
    ) : (
      <Error4O4 />
    )
  )
}

const AppStack = () => {
  return (
    <Routes>
        <Route path='/login' element={<SignIn />}></Route>
        <Route path='/' element={
          <PrivateRoute 
            component={<Home />} />
        }></Route>
        <Route path='/text-simplifications' element={<PrivateRoute roles={["admin", "client"]} component={<TextSimplificationList />} />} />

        <Route path='/doctors' element={<PrivateRoute roles={["admin"]} component={<DoctorList />} />} />
        <Route path='/exams/:examId/edit' element={<PrivateRoute roles={["admin"]}component={<ExamEdit />} />} />
        <Route path='/exams/create'  element={<PrivateRoute roles={["admin"]}  component={<ExamCreate />} />} />
        <Route path='/exams' element={<PrivateRoute roles={["admin"]} component={<ExamList />} />} />
        <Route path='/login' element={<PrivateRoute component={<SignIn />} />} />
        <Route path='/patients' element={<PrivateRoute roles={["admin"]} component={<PatientList />} />} />
        <Route path='/report-types' element={<PrivateRoute roles={["admin"]} component={<ReportTypeList />} />} />
        <Route path='/users' element={<PrivateRoute roles={["admin"]} component={<UsertList />} />} />
        <Route path='/users/:userId' element={<PrivateRoute roles={["admin"]} component={<UserEdit />} />} />
        <Route path='/users/create' element={<PrivateRoute roles={["admin"]} component={<UserCreate />} />} />
        <Route path='/text-simplification-contexts' element={<PrivateRoute roles={["admin"]} component={<SimplificationContextList />} />} />
        <Route path='/text-simplification-contexts/:textSimplificationId' element={<PrivateRoute roles={["admin"]} component={<SimplificationContextEdit />} />} />
        <Route path='/champ' element={<PrivateRoute roles={["admin"]} component={<NpsScreen />} />} />
        <Route path="*" element={<Error4O4 />} />
    </Routes>
  )
}

// const isTokenValid = (auth: Auth|null): boolean => {
//   if(!auth?.accessToken || !auth?.expiresAt) {
//       return false;
//   }
  
//   let expiresDate: Date = new Date(auth.expiresAt);
  
//   return expiresDate > new Date();
// }

export const AppNavigator = observer(() => {

  const { appStore } = useStores();

  const message = appStore.environment.messageStore.messages.length ? appStore.environment.messageStore.messages[0] : null;
  
  return (
    <Box>
      <AppStack />
      <AuthGuard />
      <Snackbar
        open={(() => {
          if(message === null || message?.content === null) {
            return false;
          }
          
          return message.content?.trim() !== "";
        })()}
        autoHideDuration={6000}
        onClose={() => appStore.environment.messageStore.shift()}
      >
        <Alert severity={message?.type as AlertColor ?? "info"} onClose={() => appStore.environment.messageStore.shift()} sx={{ width: '100%' }}>
          {message?.content}
        </Alert>
      </Snackbar>
    </Box>
    
    
  )
})

AppNavigator.displayName = "AppNavigator"

/**
 * A list of routes from which we're allowed to leave the app when
 * the user presses the back button on Android.
 *
 * Anything not on this list will be a standard `back` action in
 * react-navigation.
 *
 * `canExit` is used in ./app/app.tsx in the `useBackButtonHandler` hook.
 */
const exitRoutes = ["login"]
export const canExit = (routeName: string) => exitRoutes.includes(routeName)
