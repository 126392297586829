import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * Doctor model.
 */
export const DoctorModel = types.model("Doctor").props({
  id: types.identifierNumber,
  lastname: types.maybe(types.string),
  firstname: types.optional(types.maybeNull(types.string), null),
  place: types.optional(types.maybeNull(types.string), null)
})

type DoctorType = Instance<typeof DoctorModel>
export interface Doctor extends DoctorType {}
type DoctorSnapshotType = SnapshotOut<typeof DoctorModel>
export interface DoctorSnapshot extends DoctorSnapshotType {}
export const createExamDefaultModel = () => types.optional(DoctorModel, {} as DoctorType)