import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * Patient model.
 */
export const PatientModel = types.model("Patient").props({
  id: types.identifierNumber,
  ipp: types.maybe(types.string),
  lastname: types.maybe(types.string),
  firstname: types.maybe(types.string),
  birthdate: types.maybe(types.string),
})

type PatientType = Instance<typeof PatientModel>
export interface Patient extends PatientType {}
type PatientSnapshotType = SnapshotOut<typeof PatientModel>
export interface PatientSnapshot extends PatientSnapshotType {}
export const createPatientDefaultModel = () => types.optional(PatientModel, {} as PatientType)