import { types } from "mobx-state-tree"
import { BuildCrudModel } from "../CrudStore"
import { ICrudApi } from "src/services/api/api-crud-service";
import { Client, ClientModel } from "./client";
import { ClientApi } from "src/services/api/client-api";

const ClientStoreModel = types
  .model("ClientStoreModel")

  const CrudModel = BuildCrudModel(ClientModel)
  .actions((self) => ({
    getApi: (): ICrudApi<Client>|null => new ClientApi(self.environment.api),
  }));
  
  export const CrudClientStoreModel = types.compose(CrudModel, ClientStoreModel)