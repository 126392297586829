import { Box, Typography } from "@mui/material";
import { translate } from "../i18n";

export const Home = () => {
    return (
        <Box className="page">
            
            <Typography variant="h1" component="h1" gutterBottom>
                { translate('home.title') }
            </Typography>
            
            <img style={{ width: "100%" }} src="/images/owl-opened-wings.png" alt="Owl opened wings"/>
        </Box>
    )
}