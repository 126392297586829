import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * Message model.
 */
export const MessageModel = types.model("Message").props({
  type: types.enumeration(["error", "success", "warning"]),
  content: types.maybe(types.string)
})

type MessageType = Instance<typeof MessageModel>
export interface Message extends MessageType {}
type MessageSnapshotType = SnapshotOut<typeof MessageModel>
export interface MessageSnapshot extends MessageSnapshotType {}
export const createExamDefaultModel = () => types.optional(MessageModel, {} as MessageType)