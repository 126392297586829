import { observer } from "mobx-react";
import { useStores } from "../../models";
import { CreateOrEditFormContainer } from "src/components/CreateOrEditFormContainer";
import { SimplificationContextForm } from "src/components/simplification-contexts/SimplificationContextForm";
import { translate } from "src/i18n";
import { toDTO } from "src/models/model-dto-mapper";
import { SimplificationContextDto } from "src/models/simplification-contexts/simplification-context";
import { useEffect, useState } from "react";

export const SimplificationContextEdit = observer(() => {
    const { textSimplificationContextStore } = useStores();
    
    const [simplificationContext, setSimplificationContext] = useState<SimplificationContextDto>();

    useEffect(() => {
        if(textSimplificationContextStore.currentModel) 
        {
            setSimplificationContext(toDTO(textSimplificationContextStore.currentModel));
        }
        
    }, [textSimplificationContextStore.currentModel?.id]);

    return (
        <CreateOrEditFormContainer
            elementRouteKeyId="textSimplificationId"
            store={textSimplificationContextStore}
            navigateLink="/text-simplification-contexts"
            title={translate("text_simplification_context.edit") ?? ""} 
            model={simplificationContext}
        >
            {
                simplificationContext && 
                    <SimplificationContextForm simplificationContext={simplificationContext} setSimplificationContext={setSimplificationContext} />
            }
        </CreateOrEditFormContainer>
    )
});