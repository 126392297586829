import { useEffect } from "react";
import { useStores } from "../../models";
import { Box, Typography } from "@mui/material";
import { IColumn, CustomTable } from "src/components/lists/CustomTable";
import { TxKeyPath, translate } from "../../i18n";
import { DATE_FORMAT_API, DATE_FORMAT_UI } from "../../config/env";
import dayjs from "dayjs";
import { User } from "src/models/user/user";

export const UsertList = () => {
    const { userStore } = useStores();

    const columns: IColumn<User>[] = [
        { field: 'name', headerName: 'Name', width: 130, getValue: (user: User) => user.name ?? '' },
        { field: 'email', headerName: 'Email', width: 130, getValue: (user: User) => user.email ?? '' },
        { field: 'type', headerName: 'Type', width: 130, getValue: (user: User) => user.type ? translate(`user.types.${user.type}` as TxKeyPath) ?? '' : '' }
    ];

    useEffect(() => {
        (async() => {
            await userStore.getAll();
        })();
    }, [userStore]);

    return (
        <Box className="page">
            <Typography variant="h1" component="h1" gutterBottom>
                { translate('user.list') }
            </Typography>
            {
                userStore.models && (
                    <CustomTable<User>
                        columns={columns}
                        data={userStore.models}
                        id={(user: User) => user.id?.toString() ?? ''}
                        addButton={{
                            text: translate("user.create") ?? "",
                            redirectLink: () => "/users/create"
                        }}
                        editButton={{
                            redirectLink: (user: User) => `/users/${user.id}`
                        }}
                        deleteModal={
                            {
                                title: (user: User) => translate('common.delete', { "element": user?.name ?? '' }) ?? "",
                                store: userStore
                            }
                        }
                        actions={{width: 130}}
                    />
                )
            }
        </Box>
    )
}