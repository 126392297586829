import { ApiResponse } from "apisauce";
import { Api, ICrudConfig } from "./api";
import { GeneralApiProblem, getGeneralApiProblem } from "./api-problem";
import { ApiError, DeleteModelResult } from "./api.types";
import { ValidationError } from "src/models/error/error-validation";

interface IModelWithId {
    id: number | string
}

export interface ICrudApi<TModel> {
    getAll: () => Promise<GetAllTModelResult<TModel>>;
    createOrUpdate: (model: TModel) => Promise<GetTModelResult<TModel>>;
    delete: (model: TModel) => Promise<DeleteModelResult>;
    get: (id: number|string) => Promise<GetTModelResult<TModel>>;
}

export type GetAnySuccess<TResult> = { kind: "ok"; payload: TResult | ValidationError }
export type GetAllTModelResultSuccess<TModel> = { kind: "ok"; payload: TModel[] | ValidationError }
export type GetAllTModelResult<TModel> = GetAllTModelResultSuccess<TModel>  | GeneralApiProblem
export type GetTModelResult<TModel> = { kind: "ok"; payload: TModel | ValidationError } | GeneralApiProblem

type AnyType<TModel> = GetAnySuccess<TModel> | GetAllTModelResultSuccess<TModel> | GeneralApiProblem;

export class ApiCrudService<TModel extends IModelWithId> implements ICrudApi<TModel>
{
    protected api: Api
    private config: ICrudConfig<TModel>;

    constructor(api: Api, config: ICrudConfig<TModel>) {
        this.api = api
        this.config = config;
    }

    async get(id: number|string): Promise<GetTModelResult<TModel>> {
        const self = this;
        const routeConfig = this.config.get ? this.config.get(id) : null;

        if(!routeConfig)
        {
            throw new Error("get not implemented for this type");
        }

        return await this.performRequest(async function() {
            return await self.api.getAll(routeConfig);
        });
    }
    
    async getAll() : Promise<GetAllTModelResult<TModel>> {
        const self = this;
        const routeConfig = this.config.getAll;

        if(!routeConfig) 
        {
            throw new Error("getAll not implemented for this type");
        }

        return await this.performRequest(async function() {
            return await self.api.getAll(routeConfig);
        });
    }
    
    async createOrUpdate(model: TModel) : Promise<GetTModelResult<TModel>> {
        const self = this;
        const routeConfig = this.config.createOrUpdate ? this.config.createOrUpdate(model) : null;

        if(!routeConfig)
        {
            throw new Error("createOrUpdate not implemented for this type");
        }

        return await this.performRequest(async function() {

            const isUpdate = model.id && (
                (typeof model.id === "string" && model.id.trim() !== "") ||
                (typeof model.id === "number" && model.id > 0)
            );

            return await isUpdate ? self.api.update(routeConfig) : self.api.create(routeConfig);
        });
    }
    
    async delete(model: TModel) : Promise<DeleteModelResult> {
        const self = this;
        const routeConfig = this.config.delete ? this.config.delete(model) : null;

        if(!routeConfig)
        {
            throw new Error("delete not implemented for this type");
        }

        return await this.performRequest(async function() {
            return await self.api.delete(routeConfig);
        })
    }

    async performRequest(request: () => Promise<ApiResponse<any>>) : Promise<any>
    {
        try {
            const response = await request();
            
            if (!response.ok) {
                const problem = getGeneralApiProblem(response)
                if (problem) return problem
            }

            const payload = response.data

            return { kind: 'ok', payload }
        } catch (e) {
            return { kind: "bad-data" }
        }
    }
}