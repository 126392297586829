import { IAnyModelType,Instance, SnapshotOut, _NotCustomized, cast, types } from "mobx-state-tree"
import { GeneralApiProblem } from "src/services/api/api-problem";
import { DeleteModelResult } from "src/services/api/api.types";
import { ValidationError, ValidationErrorModel } from "./error/error-validation";
import { withEnvironment } from "./extensions/with-environment";
import { GetAllTModelResult, GetTModelResult, ICrudApi } from "src/services/api/api-crud-service";
import { __set } from "src/utils/objects";

export interface IModelWithId {
    id: number
}

export interface IControlledErrorStore<TModel>
{
    hasError: (fieldName: string) => boolean;
    getError: (fieldName: string) => string|null|undefined;
    updateField<T>(model: TModel, fieldName: string, value: T): TModel;
    getCurrentModel: () => TModel;
    currentModel: TModel | null;
    validationError: ValidationError | null;
}

interface ISendQueryParameters<TModel, TSendQueryResponsePayload extends { kind: "ok"} | GeneralApiProblem>
{
    api: ICrudApi<TModel>|null;
    performQuery: (api: ICrudApi<TModel>) => Promise<TSendQueryResponsePayload>;
    reloadStore: (payload?: any) => void;
}

interface IMessagingParameters<TModel> {
    textCreateSuccess: (model: TModel) => string;
    textUpdateSuccess: (model: TModel) => string;
}

export interface ICrudActions<ModelType>
{
    getAll: () => void;
    createOrUpdate: (model: ModelType) => void;
    clear: () => void;
}

export const BuildCrudModel = function<TModel extends IModelWithId>(
    baseModel: IAnyModelType, messagingParameters?: IMessagingParameters<IAnyModelType>) {

    type TModelType = Instance<typeof baseModel>

    type TModelSnapshotType = SnapshotOut<typeof baseModel>

    interface IModelSnapshotOut extends TModelSnapshotType {}
    
    const CrudModel = types.model(`${baseModel.name}CrudModel`)
        .extend(withEnvironment)
        .props(
            {
                models: types.optional(types.array(baseModel), []),
                currentModel: types.optional(types.maybeNull(types.safeReference(baseModel)), null),
                newModel: types.optional(types.maybeNull(baseModel), null),
                loading: false,
                validationError: types.optional(types.maybeNull(ValidationErrorModel), null)
            }
        )
        .actions((self) => ({
            getApi: (): ICrudApi<TModel>|null => null,
            save: (snapshot: IModelSnapshotOut|null) => {

                if(snapshot) {

                    const models = self.models.find(x => x.id === snapshot.id) ? 
                        self.models.map(s => {
                            return s.id === snapshot.id ? snapshot : s;
                        }) :
                        self.models.concat([cast(snapshot)]);

                    try {
                        self.models = cast(models)
                    } catch(ex) {
                        console.error(ex)
                    }
                }
            },
            saveAll: (snapshots: IModelSnapshotOut[]) => {
                try {
                    self.models = cast(snapshots)
                } catch(ex) {
                    console.error(ex)
                }
            },
            saveCurrent: (snapshot: IModelSnapshotOut|null) => {
                try {
                    self.currentModel = snapshot === null ? null : cast(snapshot.id)
                } catch(ex) {
                    console.error(ex)
                }
            },
            remove: (snapshot: TModel) => {
                self.currentModel = null

                if(!snapshot) {
                    return;
                }

                self.models = cast(self.models.filter(x => x.id !== snapshot.id));
            },
            setLoading: (loading: boolean) => {
                self.loading = loading;
            },
            saveValidationError: (error: ValidationError|null) => {
                self.validationError = cast(error)
            },
            afterCreate: () => {
                self.loading = false;
                self.validationError = null;
                self.newModel = null
                self.currentModel = null
            }
        }))
        .actions((self) => ({
            async sendQuery<TSendQueryResponsePayload extends { kind: "ok", payload?: any } | GeneralApiProblem>(
                { api, performQuery, reloadStore }: ISendQueryParameters<TModel, TSendQueryResponsePayload>
             ) {
                 
                if(!api) {
                    throw new Error("Api shall be implemented");
                }

                self.setLoading(true);

                try {
                    const result: TSendQueryResponsePayload = await performQuery(api);

                    self.setLoading(false);

                    self.saveValidationError(null);
                    
                    if(result.kind === 'ok') {
                        return reloadStore(result.payload);
                    } else if(result.kind === "rejected") {
                        self.saveValidationError(result.error as ValidationError)
                    } 

                    throw new Error(result.kind);
                }
                catch(ex) {
                    self.setLoading(false);
                    throw ex;
                }
            },
            notifySuccess(message: string) {
                self.environment.messageStore.success(message);
            },
            flush() {
                self.saveValidationError(null)
                self.setLoading(false)
                self.saveCurrent(null)
            }
        }))
        .actions((self) => ({
            getAll: async() => {

                await self.sendQuery<GetAllTModelResult<TModel>>(
                    {
                        api: self.getApi(),
                        performQuery: async(api: ICrudApi<TModel>): Promise<GetAllTModelResult<TModel>> => await api.getAll(),
                        reloadStore: (payload: any) => {
                            self.saveAll(payload)
                        }
                    }
                )
            },
            get: async(id: number|string) => {
                await self.sendQuery<GetTModelResult<TModel>>(
                    {
                        api: self.getApi(),
                        performQuery: async(api: ICrudApi<TModel>): Promise<GetTModelResult<TModel>> => await api.get(id),
                        reloadStore: (payload: TModelType) => {
                            self.save(payload)
                            self.saveCurrent(payload)
                        }
                    }
                )
            },
            createOrUpdate: async(model: any) => {

                await self.sendQuery<GetTModelResult<TModel>>(
                    {
                        api: self.getApi(),
                        performQuery: async(api: ICrudApi<TModel>): Promise<GetTModelResult<TModel>> => await api.createOrUpdate(model),
                        reloadStore: (payload: TModelType|undefined) => {
                            self.save(payload ?? null);
                            messagingParameters && self.notifySuccess(
                                model.id && model.id > 0 ? 
                                    messagingParameters.textUpdateSuccess(payload) : 
                                    messagingParameters.textCreateSuccess(payload)
                            )
                        }
                    }
                )
            },
            updateField<T>(model: TModel, fieldName: string, value: T): TModel {

                model = __set(model, fieldName, value);
                
                if(self.currentModel && model?.id === self.currentModel.id) {
                    self.saveCurrent(model);
                }

                return model;
            },
            delete: async (model: TModel) => {

                await self.sendQuery<DeleteModelResult>(
                    {
                        api: self.getApi(),
                        performQuery: async(api: ICrudApi<TModel>): Promise<DeleteModelResult> => await api.delete(model),
                        reloadStore: (_: any) => {
                            return self.remove(model);
                        }
                    }
                )
            },
            initCreate: (model: TModel) => {
                self.saveCurrent(model)
            },
            clear: () => {
                self.flush()
            },
            hasError: (fieldName: string): boolean => {

                if(self.validationError?.errors?.find(e => e.field === fieldName)) {
                    return true;
                }
        
                return false;
            },
            getError: (fieldName: string): string|null|undefined => {
                return  self.validationError?.errors?.find(e => e.field === fieldName)?.message;
            },
            getCurrentModel: () => self.currentModel ? self.currentModel : self.newModel
        }));

    return CrudModel; 
};
