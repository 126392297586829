import { Api, CrudRouteConfig, ICrudConfig } from "./api"
import { GetMeResult, UpdatePasswordResult, User } from "./api.types"
import { ApiCrudService } from "./api-crud-service"

class UserConfig implements ICrudConfig<User> 
{
  get = (id: number|string) => new CrudRouteConfig<User>(`/admin/users/${id}`);
  getAll = new CrudRouteConfig<User>("/admin/users");
  createOrUpdate = (model: User) => new CrudRouteConfig<User>(model.id && model.id > 0 ? `/admin/users/${model.id}` : '/admin/users', model);
  delete = (model: User) => new CrudRouteConfig<User>(`/admin/users/${model.id}`);
}

export class UserApi extends ApiCrudService<User> {

  constructor(api: Api) {
    super(api, new UserConfig)
  }
  
  async getMe(): Promise<GetMeResult>  {
    return await this.performRequest(async() => await this.api.getMe())
  }

  async updatePassword(currentPassword: string, newPassword: string, newPasswordConfirm: string): Promise<UpdatePasswordResult>  {
    return await this.performRequest(async() => await this.api.updatePassword(currentPassword, newPassword, newPasswordConfirm))
  }
}
