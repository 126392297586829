import { Instance, SnapshotOut, cast, types } from "mobx-state-tree"
import { Doctor, DoctorModel, DoctorSnapshot } from "./doctor"
import { DoctorApi } from "../../services/api/doctor-api"
import { withEnvironment } from "../extensions/with-environment"
import { translate } from "../../i18n"
import { BuildCrudModel } from "../CrudStore"
import { ICrudApi } from "src/services/api/api-crud-service"

const CrudModel = BuildCrudModel(DoctorModel)
.extend(withEnvironment)
.actions((self) => ({
  getApi: (): ICrudApi<Doctor>|null => new DoctorApi(self.environment.api),
}));

/**
 * store containing Doctors
 */
export const DoctorStoreModel = types
  .model("DoctorStore")
  .extend(withEnvironment)

export const CrudDoctorStoreModel = types.compose(CrudModel, DoctorStoreModel)
