import { useTheme } from "@mui/material";

import {
  LIGHT_MODE_THEME,
  LIGHT_SELECTED_NAVIGATION_COLOR,
  DARK_SELECTED_NAVIGATION_COLOR,
  LIGHT_NAVIGATION_BAR_BACKGROUND_COLOR,
  DARK_NAVIGATION_BAR_BACKGROUND_COLOR,
  LIGHT_NAVIGATION_BAR_SELECTED_BACKGROUND_COLOR
} from "./colors";

export function useThemeProperties() {
  const theme = useTheme();

  const navMenuIconColor = theme.palette.primary.main;

  const navMenuItemOnHoverBackgroundColor =
    theme.palette.mode === LIGHT_MODE_THEME ? LIGHT_SELECTED_NAVIGATION_COLOR : DARK_SELECTED_NAVIGATION_COLOR;

  const navMenuSpecialItemBackgroundColor = "blue";
  const navMenuCurrentItemBackgroundColor = LIGHT_NAVIGATION_BAR_SELECTED_BACKGROUND_COLOR;
  const navMenuBorderColor = theme.palette.primary.main;

  const navMenuBackgroundColor = theme.palette.mode === LIGHT_MODE_THEME ? LIGHT_NAVIGATION_BAR_BACKGROUND_COLOR : DARK_NAVIGATION_BAR_BACKGROUND_COLOR;
  const invisibleModalBackgroundColor = "transparent";
  const modalBackgroundColor = "purple";

  return {
    
    navMenuIconColor,
    navMenuItemOnHoverBackgroundColor,
    navMenuSpecialItemBackgroundColor,
    navMenuCurrentItemBackgroundColor,
    navMenuBorderColor,
    navMenuBackgroundColor,
    invisibleModalBackgroundColor,
    modalBackgroundColor
  };
}
