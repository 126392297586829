import { Api } from "./api"
import { AuthResult } from "./api.types"
import { getGeneralApiProblem } from "./api-problem"

export class AuthApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }
  
  async login(login: string, passwd: string) : Promise<AuthResult> {
    try {
      const response = await this.api.login(login, passwd)

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const payload = response.data
      // this.api.setHeader('Authorization', 'Bearer ' + userAuth)

      return { kind: 'ok', payload }
    } catch (e) {
      return { kind: "bad-data" }
    }
  }

  async checkOtpCode(userId: number, code: string): Promise<AuthResult> {
    try {
      const response = await this.api.checkOtpCode(userId, code)
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }
      const payload = response.data

      return { kind: 'ok', payload }
    } catch (e) {
      return { kind: "bad-data" }
    }
  }
}
