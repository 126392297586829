import { Api, CrudRouteConfig, ICrudConfig } from "./api"
import { GetMeResult, UpdatePasswordResult, User } from "./api.types"
import { ApiCrudService } from "./api-crud-service"
import { UserType } from "src/models/user-types/user-type";

class UserTypeApiConfig implements ICrudConfig<UserType> 
{
  getAll = new CrudRouteConfig<UserType>("/admin/user-types");
}

export class UserTypeApi extends ApiCrudService<UserType> {
  constructor(api: Api) {
    super(api, new UserTypeApiConfig)
  }
}
