import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * ContextType model.
 */
export const ContextTypeModel = types.model("ContextType").props({
  id: types.identifierNumber,
  code: types.maybe(types.string),
  description: types.maybe(types.string)
})

type ContextTypeType = Instance<typeof ContextTypeModel>
export interface ContextType extends ContextTypeType {}
type ContextTypeSnapshotType = SnapshotOut<typeof ContextTypeModel>
export interface ContextTypeSnapshot extends ContextTypeSnapshotType {}
export const createExamDefaultModel = () => types.optional(ContextTypeModel, {} as ContextTypeType)

export interface ContextTypeDto 
{
  id: number;
  code: string;
  description: string | undefined;
}
