import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * MedicalModel model.
 */
export const MedicalModelModel = types.model("MedicalModel").props({
  id: types.identifierNumber,
  code: types.maybe(types.string),
  name: types.maybe(types.string),
  is_default: types.maybe(types.boolean),
})

type MedicalModelType = Instance<typeof MedicalModelModel>
export interface MedicalModel extends MedicalModelType {}
type MedicalModelSnapshotType = SnapshotOut<typeof MedicalModelModel>
export interface MedicalModelSnapshot extends MedicalModelSnapshotType {}
export const createExamDefaultModel = () => types.optional(MedicalModelModel, {} as MedicalModelType)

export interface MedicalModelDto 
{
  id: number;
  code: string;
  name: string | undefined;
  is_default: boolean;
}
