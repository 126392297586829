import { types } from "mobx-state-tree"
import { ReportType, ReportTypeModel } from "./report-type"
import { ReportTypeApi } from "../../services/api/report-type-api"
import { BuildCrudModel } from "../CrudStore"
import { ICrudApi } from "src/services/api/api-crud-service"

const CrudModel = BuildCrudModel(ReportTypeModel)
.actions((self) => ({
  getApi: (): ICrudApi<ReportType>|null => new ReportTypeApi(self.environment.api),
}));

/**
 * store containing ReportTypes
 */
const ReportTypeStoreModel = types.model("ReportTypeStore");

export const CrudReportTypeStoreModel = types.compose(CrudModel, ReportTypeStoreModel)