import { ApisauceInstance, create, ApiResponse, Monitor } from "apisauce"
import { getGeneralApiProblem } from "./api-problem"
import { ApiConfig, DEFAULT_API_CONFIG } from "./api-config"
import * as Types from "./api.types"
import { Patient } from "../../models/patient/patient"
import { ReportType } from "../../models/reportTypes/report-type"
import { Exam } from "../../models/exam/exam"
import { Doctor } from "../../models/doctor/doctor"
import { DATE_FORMAT_UI } from "../../config/env"

const dateFormat = DATE_FORMAT_UI

interface ICrudRouteConfig<TModel>
{
  route: string;
  params?: TModel;
}


export class CrudRouteConfig<TModel> implements ICrudRouteConfig<TModel> 
{
  route: string;
  params?: TModel;

  constructor(route: string, params: TModel|undefined = undefined) {
    this.route = route;
    this.params = params;
  }
}

export interface ICrudConfig<TModel>
{
    get?: (id: number|string) => ICrudRouteConfig<TModel>;
    getAll?: ICrudRouteConfig<TModel>;
    createOrUpdate?: (model: TModel) => ICrudRouteConfig<TModel>;
    delete?: (model: TModel) => ICrudRouteConfig<TModel>;
}

/**
 * Manages all requests to the API.
 */
export class Api {
  /**
   * The underlying apisauce instance which performs the requests.
   */
  apisauce: ApisauceInstance|null = null

  /**
   * Configurable options.
   */
  config: ApiConfig

  /**
   * Creates the api.
   *
   * @param config The configuration to use.
   */
  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    this.config = config
  }

  /**
   * Sets up the API.  This will be called during the bootup
   * sequence and will happen before the first React component
   * is mounted.
   *
   * Be as quick as possible in here.
   */
  setup() {
    // construct the apisauce instance
    
    this.apisauce = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*"
      },
    });
  }

  setHeader(key: string, value: string) {
    this.getApiSauceInstance().setHeader(key, value)
  }

  setAccessToken(value: string|null) {
    this.setHeader('Authorization', `Bearer ${value}`)
  }

  addMonitor(monitor: Monitor) {
    // this.monitor = monitor
    this.getApiSauceInstance().addMonitor(monitor)
  }

  async get<TModel>(routeConfig: ICrudRouteConfig<TModel>): Promise<ApiResponse<any>> 
  {
    const response: ApiResponse<any> = await this.getApiSauceInstance().get(routeConfig.route);

    return response;
  }

  async getAll<TModel>(routeConfig: ICrudRouteConfig<TModel>): Promise<ApiResponse<any>> 
  {
    const response: ApiResponse<any> = await this.getApiSauceInstance().get(routeConfig.route);

    return response;
  }

  async update<TModel>(routeConfig: ICrudRouteConfig<TModel>): Promise<ApiResponse<any>> 
  {
    const response: ApiResponse<any> = await this.getApiSauceInstance().patch(routeConfig.route, routeConfig.params);

    return response;
  }

  async create<TModel>(routeConfig: ICrudRouteConfig<TModel>): Promise<ApiResponse<any>> 
  {
    const response: ApiResponse<any> = await this.getApiSauceInstance().post(routeConfig.route, routeConfig.params);

    return response;
  }

  async delete<TModel>(routeConfig: ICrudRouteConfig<TModel>): Promise<ApiResponse<any>> 
  {
    const response: ApiResponse<any> = await this.getApiSauceInstance().delete(routeConfig.route);

    return response;
  }

  /**
   * Gets a list of users.
   */
  async getUsers(): Promise<ApiResponse<any>> {
    // make the api call
    const response: ApiResponse<any> = await this.getApiSauceInstance().get(`/users`)

    return response;
  }

  /**
   * Gets a single user by ID
   */

  async getUser(id: string): Promise<ApiResponse<any>> {
    // make the api call
    return await this.getApiSauceInstance().get(`/users/${id}`)
  }
  
  async login(login: string, passwd: string): Promise<ApiResponse<any>> {
    const response: ApiResponse<any> = await this.getApiSauceInstance().post(`/admin/oauth/auth`, {
      grant_type: 'password',
      client_id: this.config.client_id,
      client_secret: this.config.client_secret,
      username: login,
      password: passwd
    })

    return response
  }

  async getExam(id: string): Promise<ApiResponse<any>> {
    const response: ApiResponse<any> = await this.getApiSauceInstance().get(`/exams/${id}`, { "date-format": dateFormat  })

    return response
  }

  async getExams(): Promise<ApiResponse<any>> {
    const response: ApiResponse<any> = await this.getApiSauceInstance().get('/exams', { "date-format": dateFormat })

    return response
  }

  async getReportTypes(): Promise<ApiResponse<any>> {
    const response: ApiResponse<any> = await this.getApiSauceInstance().get('/admin/report-types')

    return response
  }

  async updateReportType(reportType: ReportType): Promise<ApiResponse<any>> {
    const response: ApiResponse<any> = await this.getApiSauceInstance().patch(`/admin/report-types/${reportType.id}`, reportType)

    return response
  }

  async deleteReportType(reportType: ReportType): Promise<ApiResponse<any>> {
    const response: ApiResponse<any> = await this.getApiSauceInstance().delete(`/admin/report-types/${reportType.id}`)

    return response
  }

  async createReportType(reportType: ReportType): Promise<ApiResponse<any>> {
    const response: ApiResponse<any> = await this.getApiSauceInstance().post('/admin/report-types', reportType)

    return response
  }

  async getDoctors(): Promise<ApiResponse<any>> {
    const response: ApiResponse<any> = await this.getApiSauceInstance().get('/admin/doctors')

    return response
  }

  async getPatients(): Promise<ApiResponse<any>> {
    const response: ApiResponse<any> = await this.getApiSauceInstance().get('/admin/patients')

    return response
  }

  async getPatient(id: number): Promise<ApiResponse<any>> {
    const response: ApiResponse<any> = await this.getApiSauceInstance().get(`/admin/patients/${id}`)

    return response
  }

  async getLangs(): Promise<ApiResponse<any>> {
    const response: ApiResponse<any> = await this.getApiSauceInstance().get('/langs')

    return response
  }

  async updatePatient(patient: Patient): Promise<ApiResponse<any>> {
    const response: ApiResponse<any> = await this.getApiSauceInstance().patch(`/admin/patients/${patient.id}`, patient)

    return response
  }

  async deletePatient(patient: Patient): Promise<ApiResponse<any>> {
    const response: ApiResponse<any> = await this.getApiSauceInstance().delete(`/admin/patients/${patient.id}`)

    return response
  }

  async createPatient(patient: Patient): Promise<ApiResponse<any>> {
    const response: ApiResponse<any> = await this.getApiSauceInstance().post('/admin/patients', patient)

    return response
  }

  async updateDoctor(doctor: Doctor): Promise<ApiResponse<any>> {
    const response: ApiResponse<any> = await this.getApiSauceInstance().patch(`/admin/doctors/${doctor.id}`, doctor)

    return response
  }

  async deleteDoctor(doctor: Doctor): Promise<ApiResponse<any>> {
    const response: ApiResponse<any> = await this.getApiSauceInstance().delete(`/admin/doctors/${doctor.id}`)

    return response
  }

  async createDoctor(doctor: Doctor): Promise<ApiResponse<any>> {
    const response: ApiResponse<any> = await this.getApiSauceInstance().post('/admin/doctors', doctor)

    return response
  }

  async updateExam(exam: Exam): Promise<ApiResponse<any>> {
    const response: ApiResponse<any> = await this.getApiSauceInstance().patch(`/admin/exams/${exam.id}`, {
      ...exam,
      ...{
        diagnostic_doctor_id: exam.diagnostic?.id,
        prescriber_doctor_id: exam.prescriber?.id,
        responsible_doctor_id: exam.responsible?.id,
        patient_id: exam.patient?.id,
      }
    })

    return response
  }

  async deleteExam(exam: Exam): Promise<ApiResponse<any>> {
    const response: ApiResponse<any> = await this.getApiSauceInstance().delete(`/admin/exams/${exam.id}`)

    return response
  }
  
  async translateExamTexts(exam: Exam, provider: string, lang: string): Promise<ApiResponse<any>> {
    const response: ApiResponse<any> = await this.getApiSauceInstance().post(`/admin/translate-texts`, {
      texts: exam.texts,
      language_code: lang,
      provider
    })

    return response
  }

  async simplifyText(exam: Exam, text: string, model: string, contextType: string, lang: string): Promise<ApiResponse<any>> {
    const response: ApiResponse<any> = await this.getApiSauceInstance().post(`/admin/simplify-text`, {
      text,
      simplification_method: model,
      context_type: contextType,
      lang
    })

    return response
  }

  async createExam(exam: Exam): Promise<ApiResponse<any>> {
    const response: ApiResponse<any> = await this.getApiSauceInstance().post('/admin/exams', {
      ...exam,
      ...{
        diagnostic_doctor_id: exam.diagnostic?.id,
        prescriber_doctor_id: exam.prescriber?.id,
        responsible_doctor_id: exam.responsible?.id,
        patient_id: exam.patient?.id,
      }
    })

    return response
  }

  async checkOtpCode(userId: number, code: string):  Promise<ApiResponse<any>> {
    const response: ApiResponse<any> = await this.getApiSauceInstance().post(`/oauth/auth/${userId}`, {
      client_id: 1,
      client_secret: 'bwOOKg0PVOITIVZGx6Fz33xbOlNp2NJqtRBlEu6C',
      token: code
    })

    return response
  }

  async getMe(): Promise<ApiResponse<any>> {
    const response: ApiResponse<any> = await this.getApiSauceInstance().get('/me')

    return response
  }

  async addComment(id: number, comment: string): Promise<ApiResponse<any>> {
    const response: ApiResponse<any> = await this.getApiSauceInstance().post(`/exams/${id}/comment`, {
      comment
    })
    return response
  }

  async updateStatust(id: number, status: string): Promise<ApiResponse<any>> {
    const response: ApiResponse<any> = await this.getApiSauceInstance().patch(`/exams/${id}`, {
      status
    })
    return response
  }

  async updatePassword(currentPassword: string, newPassword: string, newPasswordConfirm: string): Promise<ApiResponse<any>>  {
    const response: ApiResponse<any> = await this.getApiSauceInstance().patch(`/me/password`, { currentPassword, newPassword, newPasswordConfirm })
    return response
  }

  async uploadImage(id: number, image: File) {
    const formData = new FormData();
    formData.append("image", image);

    const response: ApiResponse<any> = await this.getApiSauceInstance().post(`/images`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
    return response
  }

  async uploadExamTexts(id: number, provider: string, file: File, lang: string, selectedContextType: string, activateTextSimplification: boolean) {
    const formData = new FormData();
    formData.append("report", file);
    formData.append("lang", lang);
    formData.append("context_type", selectedContextType);
    formData.append("simplify_text", activateTextSimplification ? "1" : "0");
    formData.append("provider", provider);
 
    const response: ApiResponse<any> = await this.getApiSauceInstance().post(`/admin/exams/${id}/texts`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
    return response
  }

  async getFileContent(file: File) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("file_type", "word");

    const response: ApiResponse<any> = await this.getApiSauceInstance().post(`/admin/file-to-text`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
    return response
  }
  
  async getContextTypes(): Promise<ApiResponse<any>> {
    const response: ApiResponse<any> = await this.getApiSauceInstance().get('admin/context-types')

    return response
  }

  async getMedicalModels(): Promise<ApiResponse<any>> {
    const response: ApiResponse<any> = await this.getApiSauceInstance().get('admin/medical-models')

    return response
  }

  async getTranslationProviders(): Promise<ApiResponse<any>> {
    const response: ApiResponse<any> = await this.getApiSauceInstance().get('admin/translation-providers')

    return response
  }

  async exportTextSimplifications(): Promise<ApiResponse<any>> {
    const response: ApiResponse<any> = await this.getApiSauceInstance().get('admin/text-simplifications/export', {}, {
      responseType: 'blob',
    })

    return response
  }

  async exportChampFeedbacks(): Promise<ApiResponse<any>> {
    const response: ApiResponse<any> = await this.getApiSauceInstance().get('admin/champ/export', {}, {
      responseType: 'blob',
    })

    return response
  }

  async uploadSimplificationContextFromJsonContent(content: any): Promise<ApiResponse<any>> {
    const response: ApiResponse<any> = await this.getApiSauceInstance().post('admin/text-simplification-contexts/upload', content)

    return response
  }

  async getChampNps(): Promise<ApiResponse<any>> {
    const response: ApiResponse<any> = await this.getApiSauceInstance().get('admin/champ/nps')

    return response
  }

  protected getApiSauceInstance(): ApisauceInstance {
    if (this.apisauce === null) {
      this.setup()
    }

    if(this.apisauce === null) {
      throw new Error('ApiSauce instance is null');
    }

    return this.apisauce;
  }
}
