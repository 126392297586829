import { Box, Button, Modal, Grid, Alert } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { translate, TxKeyPath } from "../../i18n";
import { observer } from "mobx-react";
import { useState } from "react";
import { ModelInstanceType, _NotCustomized } from "mobx-state-tree";

export interface IDeleteActions<ModelType>
{
    getAll: () => void;
    delete: (model: ModelType) => void;
}

interface IDeleteModalProps<ModelType> {
    model: ModelType|null;
    setModel: (model: ModelType|null) => void;
    store: ModelInstanceType<any, IDeleteActions<ModelType>>;
    title: (model: ModelType) => string;
}

export const DeleteModal = observer(({ model, setModel, store, title }: IDeleteModalProps<any>) => {

    const [error, setError] = useState<string|null>(null);

    const save = async() => {
        setError(null);

        if (model) {
            try {
                await store.delete(model);
                setModel(null);
            }
            catch(e: any) {
                setError(translate(`errors.api.${e.message}` as TxKeyPath));
            }
        }
    };

    const cancel = () => {
        setModel(null);
    }
    
    return (
        <Modal
            open={model !== null}
            onClose={() => {
                setModel(null);
                setError(null);
            }}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
        >
            <Box sx={{ 
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: "60%",
                bgcolor: 'background.paper',
                borderRadius: 10,
                boxShadow: 24,
                p: 4 }}>
                    {
                        error && (
                            <Alert sx={{ mb: 2 }} severity="error">{ error }</Alert>
                        )
                    }
                    <Alert sx={{ mb: 2 }} severity="warning">{ title(model) }</Alert>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Button onClick={save} color="error" variant="outlined" sx={{mt: 2, width: '100%'}} endIcon={ <SaveIcon />}>
                                { translate('common.yes') }
                            </Button>
                            <Button onClick={cancel} variant="outlined" sx={{mt: 2, width: '100%'}} endIcon={ <CancelIcon />}>
                                { translate('common.no') }
                            </Button>
                        </Grid>
                    </Grid>
            </Box>
        </Modal>
    )
});