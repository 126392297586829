import { Api } from "./api"
import { GetLangsResult } from "./api.types"
import { getGeneralApiProblem } from "./api-problem"

export class LangApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }
  
  async getAll() : Promise<GetLangsResult> {
    try {
      const response = await this.api.getLangs()

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const payload = response.data

      return { kind: 'ok', payload }
    } catch (e) {
      return { kind: "bad-data" }
    }
  }
}
