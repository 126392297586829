import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Exam, ExamModel } from "../../models/exam/exam";
import { useStores } from "../../models";
import { Box, Backdrop, CircularProgress } from "@mui/material";

import { translate } from "../../i18n";
import { ExamForm } from "../../components/exams/ExamForm";
import { ConclusionListModel } from "src/models/conclusions/conclusion-list";
import { ConclusionModel } from "src/models/conclusions/conclusion";

export const ExamCreate = observer(() => {
    const { examStore } = useStores();
    const [exam, setExam] = useState<Exam>(ExamModel.create({
        id: -1,
        exam_id: "",
        type: "",
        hash: "",
        image: "",
        logo: "",
        place: "",
        exam_date: null,
        report_date: "",
        indication: "",
        comment: "",
        diagnostic: null,
        prescriber: null,
        responsible: null,
        patient: null,
        texts: ConclusionListModel.create({
            scientist: ConclusionModel.create({
                level: "scientist",
                paragraphs: []
            }),
            simple: ConclusionModel.create({
                level: "simple",
                paragraphs: []
            })
        }),

        readed: false,
        understood: false,
        // has_comments: false
    } as Exam));

    useEffect(() => {
        examStore.setLoading(false);
    }, [examStore]);

    return (
        <Box className="page">
                {
                    examStore.loading &&
                    (
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={true}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    ) 
                }
                {
                    exam && (
                        <ExamForm 
                            exam={exam} title={translate('exam.create') ?? ''} 
                            setExam={setExam} 
                            redirectAfterSave={true}
                        />
                    )
                }
                
        </Box>
    )
});