import { Instance, SnapshotOut, types } from "mobx-state-tree";

export const LangModel = types.model("Lang").props({
    id: types.identifierNumber,
    name: types.maybe(types.string),
    code: types.maybe(types.string),
});

type LangType = Instance<typeof LangModel>
export interface Lang extends LangType {}
type LangSnapshotType = SnapshotOut<typeof LangModel>
export interface LangSnapshot extends LangSnapshotType {}
export const createLangDefaultModel = () => types.optional(LangModel, {} as LangType)