import { Instance, SnapshotOut, types } from "mobx-state-tree";

export const ReportTypeModel = types.model("ReportType").props({
    id: types.identifierNumber,
    code: types.maybe(types.string)
});

type ReportTypeType = Instance<typeof ReportTypeModel>
export interface ReportType extends ReportTypeType {}
type ReportTypeSnapshotType = SnapshotOut<typeof ReportTypeModel>
export interface ReportTypeSnapshot extends ReportTypeSnapshotType {}
export const createReportTypeDefaultModel = () => types.optional(ReportTypeModel, {} as ReportTypeType)