import { Instance, SnapshotOut, cast, types } from "mobx-state-tree"
import { MessageModel } from "./message"
import { withEnvironment } from "../extensions/with-environment"

/**
 * store containing Messages
 */
export const MessageStoreModel = types
  .model("MessageStore")
  .props({
    messages: types.optional(types.array(MessageModel), []),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    success(content: string) {
      self.messages.push(MessageModel.create({
        content,
        type: "success"
      }));
    },
    info(content: string) {
      self.messages.push(MessageModel.create({
        content,
        type: "info"
      }));
    },
    error(content: string) {
      self.messages.push(MessageModel.create({
        content,
        type: "error"
      }));
    },

    shift() {
      self.messages = cast(self.messages.filter((_, index) => index !== 0));
    }
  }))

type MessageStoreType = Instance<typeof MessageStoreModel>
export interface MessageStore extends MessageStoreType {}
type MessageStoreSnapshotType = SnapshotOut<typeof MessageStoreModel>
export interface MessageStoreSnapshot extends MessageStoreSnapshotType {}
export const createMessageStoreDefaultModel = () => types.optional(MessageStoreModel, {} as MessageStoreType)
