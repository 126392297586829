import { Instance, SnapshotOut, types } from "mobx-state-tree"
/**
 * Rick and AppSettings model.
 */
export const AppSettingsModel = types.model("AppSettings").props({
  theme: types.maybeNull(types.string)
})
// .extend(withEnvironment)

type AppType = Instance<typeof AppSettingsModel>
export interface App extends AppType {}
export interface AppUpdate {
  readed: boolean,
  understood: boolean,
}
type AppSnapshotType = SnapshotOut<typeof AppSettingsModel>
export interface AppSnapshot extends AppSnapshotType {}
export const createAppDefaultModel = () => types.optional(AppSettingsModel, {})

