import { Instance, SnapshotOut, cast, types } from "mobx-state-tree"
import { LangModel, LangSnapshot } from "./lang"
import { LangApi } from "../../services/api/lang-api"
import { withEnvironment } from "../extensions/with-environment"

/**
 * store containing Langs
 */
export const LangStoreModel = types
  .model("LangStore")
  .props({
    langs: types.optional(types.array(LangModel), []),
    loading:types.optional(types.maybeNull(types.boolean), null)
  })
  .extend(withEnvironment)
  .actions((self) => ({
    saveLang: (langSnapshots: LangSnapshot|null) => {
      self.loading = false

      if(langSnapshots) {
        const langs = self.langs.filter(s => s.id !== langSnapshots.id).concat([cast(langSnapshots)]);
        try {
          self.langs = cast(langs)
        } catch(ex) {
          console.error(ex)
        }
      }
    },
    saveLoading: (loading: boolean) => {
      self.loading = loading
    },
    clear: () => {
      self.langs = cast([]);
    }
  }))
  .actions((self) => ({
    getLangs: async () => {

      self.saveLoading(true); 
      self.clear();
      
      const api = new LangApi(self.environment.api)

      const result = await api.getAll()

      self.saveLoading(false); 

      if(result.kind === 'ok') {
        return result.payload.map(x => self.saveLang(x));
      }
    },

    clearLoading: () => self.saveLoading(false)
  }))

type LangStoreType = Instance<typeof LangStoreModel>
export interface LangStore extends LangStoreType {}
type LangStoreSnapshotType = SnapshotOut<typeof LangStoreModel>
export interface LangStoreSnapshot extends LangStoreSnapshotType {}
export const createLangStoreDefaultModel = () => types.optional(LangStoreModel, {} as LangStoreType)
