import { Instance, types } from "mobx-state-tree"

export const NpsScoreModel = types.model("NpsScore").props({
  total_positive_value: types.number,
  total_negative_value: types.number,
  nps: types.number,
  total: types.number
})

type NpsScoreModelType = Instance<typeof NpsScoreModel>
export interface NpsScore extends NpsScoreModelType {}