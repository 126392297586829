import { Backdrop, CircularProgress } from "@mui/material"

interface IProps 
{
    isLoading: boolean;
}

export const LoadingBar = ({ isLoading }: IProps) => {
    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}