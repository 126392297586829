import { types } from "mobx-state-tree"
import { UserApi } from "../../services/api/user-api"
import { BuildCrudModel } from "../CrudStore"
import { ICrudApi } from "src/services/api/api-crud-service";
import { UserType, UserTypeModel } from "./user-type";
import { UserTypeApi } from "src/services/api/user-type-api";

const UserTypeStoreModel = types
  .model("UserTypeStoreModel")

  const CrudModel = BuildCrudModel(UserTypeModel)
  .actions((self) => ({
    getApi: (): ICrudApi<UserType>|null => new UserTypeApi(self.environment.api),
  }));
  
  export const CrudUserTypeStoreModel = types.compose(CrudModel, UserTypeStoreModel)