import { useEffect } from "react";
import { useStores } from "../../models";
import { Backdrop, Box, Button, CircularProgress, Typography } from "@mui/material";
import { IColumn, CustomTable } from "src/components/lists/CustomTable";
import { TxKeyPath, translate } from "../../i18n";
import { DATE_FORMAT_WITH_TIME_API, DATE_FORMAT_WITH_TIME_UI } from "../../config/env";
import dayjs from "dayjs";
import { TextSimplification } from "src/models/text-simplifications/text-simplification";
import { observer } from "mobx-react";

export const TextSimplificationList = observer(() => {
    const { textSimplificationStore } = useStores();

    const MAX_NB_CHARS = 20;

    const normalizeText = (text: string) => {
        if(!text) {
            return text;
        }

        return text.length > MAX_NB_CHARS ? text.substring(0, MAX_NB_CHARS) + "..." : text;
    }
    const columns: IColumn<TextSimplification>[] = [
        { field: 'date', headerName: 'Date', width: 130, getValue: (textSimplification: TextSimplification) => dayjs(textSimplification.date, DATE_FORMAT_WITH_TIME_API).format(DATE_FORMAT_WITH_TIME_UI) ?? ''},
        { field: 'lang', headerName: 'Lang', width: 130, getValue: (textSimplification: TextSimplification) => textSimplification.lang ?? '' },
        { field: 'medical_model', headerName: 'Model', width: 130, getValue: (textSimplification: TextSimplification) => textSimplification.medical_model ?? "" },
        { field: 'medical_context_type', headerName: 'Disease', width: 130, getValue: (textSimplification: TextSimplification) => textSimplification.medical_context_type ?? "" },
        { field: 'scientist_text', headerName: 'Scientist text', width: 130, getValue: (textSimplification: TextSimplification) => normalizeText(textSimplification.scientist_text) },
        { field: 'simplified_text', headerName: 'Simplified text', width: 130, getValue: (textSimplification: TextSimplification) => normalizeText(textSimplification.simplified_text) },
        { field: 'elapsed_time_in_seconds', headerName: 'Time', width: 130, getValue: (textSimplification: TextSimplification) => textSimplification.elapsed_time_in_seconds.toString() + "s" },
        { field: 'ip_address', headerName: 'IP', width: 130, getValue: (textSimplification: TextSimplification) => textSimplification.ip_address },
    ];

    useEffect(() => {
        (async() => {
            await textSimplificationStore.getAll();
        })();
    }, [textSimplificationStore]);

    const exportTextSimplifications = async() => {
        await textSimplificationStore.export();
    };

    return (
        <Box className="page">
            <Typography variant="h1" component="h1" gutterBottom>
                { translate('text_simplification.list') }
            </Typography>

            {
                textSimplificationStore.loading &&
                (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={true}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                )
            }
            
            <Button component="label" variant="contained" onClick={exportTextSimplifications}>
                Export
            </Button>
            
            {
                textSimplificationStore.models && (
                    <CustomTable<TextSimplification>
                        columns={columns}
                        data={textSimplificationStore.models}
                        id={(textsimplification: TextSimplification) => textsimplification.id?.toString() ?? ''}
                        actions={{width: 130}}
                    />
                )
            }
        </Box>
    )
})