import { useEffect } from "react";
import { useStores } from "../../models";
import { Box, Typography } from "@mui/material";
import { IColumn, CustomTable } from "src/components/lists/CustomTable";
import { Doctor } from "../../models/doctor/doctor";
import { translate } from "../../i18n";

export const DoctorList = () => {
    const { doctorStore } = useStores();

    const columns: IColumn<Doctor>[] = [
        { field: 'lastname', headerName: 'Lastname', width: 130, getValue: (doctor: Doctor) => doctor.lastname ?? '' },
        { field: 'firstname', headerName: 'Firstname', width: 130, getValue: (doctor: Doctor) => doctor.firstname ?? '' },
        { field: 'place', headerName: 'Place', width: 130, getValue: (doctor: Doctor) => doctor.place ?? '' }
    ];

    useEffect(() => {
        (async() => {
            await doctorStore.getAll();
        })();
    }, [doctorStore]);

    return (
        <Box className="page">
            <Typography variant="h1" component="h1" gutterBottom>
                { translate('doctor.list') }
            </Typography>
            {
                doctorStore.models && (
                    <CustomTable<Doctor>
                        columns={columns}
                        data={doctorStore.models}
                        id={(doctor: Doctor) => doctor.id.toString()}
                        actions={{width: 130}}
                        addEditModal={
                            {
                                store: doctorStore,
                                columns: [
                                    {
                                        attr: 'lastname',
                                        label: translate('doctor.lastname') ?? ''
                                    },
                                    {
                                        attr: 'firstname',
                                        label: translate('doctor.firstname') ?? ''
                                    },
                                    {
                                        attr: 'place',
                                        label: translate('doctor.place') ?? ''
                                    }
                                ]
                            }
                        }
                        addButton={{
                            text: translate('doctor.create') ?? ""
                        }}
                        deleteModal={
                            {
                                title: (doctor: Doctor) => translate('common.delete', { "element": doctor ? `${doctor.firstname} ${doctor.lastname}` : '' }) ?? "",
                                store: doctorStore
                            }
                        }
                    />
                )
            }
            
                
        </Box>
    )
}