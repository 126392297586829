import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { Box, ClickAwayListener, Collapse, Divider, IconButton, Link, List, ListItemButton, ListItemIcon, ListItemText, styled, Toolbar, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SummarizeIcon from '@mui/icons-material/Summarize';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import EngineeringIcon from '@mui/icons-material/Engineering';
import NavigateNext from '@mui/icons-material/NavigateNext';
import GroupsIcon from '@mui/icons-material/Groups';
import PeopleIcon from '@mui/icons-material/People';
import { ReactComponent as DoctorIcon } from "../images/doctor-icon.svg";
import { ReactComponent as ChampBottle } from "../images/champ-bottle.svg";
import React, { useState } from 'react';
import { useStores } from 'src/models';
import { observer } from 'mobx-react';
import {ReactComponent as OwlImage} from "../images/owl.svg";
import { useThemeProperties } from 'src/themes/useThemeProperties';
import { FadeInElement } from './FadeInElement';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link as ReactLink } from 'react-router-dom';
import { UserTypes } from 'src/enums/UserTypes';

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
  }
  
const AppBarContainer = styled(MuiAppBar, {
  shouldForwardProp: (prop: string) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

interface INavigationMenuItemProperties {
  icon: React.ReactNode;
  link?: string;
  submenu?: boolean;
  label: string;
  expanded: boolean;
  current?: boolean;
  special?: boolean;
  onClick?: (event: any) => void;
}

export function NavigationMenuItem(props: INavigationMenuItemProperties) {
  const themeProps = useThemeProperties();

  return (
    <Link
      color="primary"
      href={props.link ?? ""}
      underline="none"
      sx={{
        color: themeProps.navMenuIconColor,
        px: 2,
        py: 1,
        width: "100%",

        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        "&:hover": {
          background: themeProps.navMenuItemOnHoverBackgroundColor,
        },
        ...(props.special && {
          background: themeProps.navMenuSpecialItemBackgroundColor,
          "&:hover": {
            background: themeProps.navMenuItemOnHoverBackgroundColor,
          },
        }),
        ...(props.current && {
          background: themeProps.navMenuCurrentItemBackgroundColor,
          "&:hover": {
            background: themeProps.navMenuItemOnHoverBackgroundColor
          },
        }),
      }}
      onClick={props.onClick}
    >
      {props.icon}
      <Box
        sx={{
          width: "100%",
        }}
      >
        {props.expanded && (
         <Typography
          variant="subtitle2"
          sx={{
            ml: 1,
            whiteSpace: "nowrap",
            color: "black",
          }}
        >
          {props.label}
        </Typography>
        )}
      </Box>
      {props.expanded && (props.submenu ? <NavigateNext /> : <></>)}
    </Link>
  );
}

interface INavigationSidebarProperties {
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
}

interface INavigationLinkProperties {
  title: string;
  icon: React.ReactNode;
  href?: string;
  externalHref?: string;
  children?: Array<INavigationLinkProperties>;
  visible: boolean;
}

export const CollapsedNavbarWidth: string = "3.9rem";
export const ExpandedNavbarWidth: string = "16rem";

const AppBar = observer((props: INavigationSidebarProperties) => {

    const toggleDrawer = () => {
        props.setExpanded(!props.expanded);
    };

    const theme = useTheme();

    const { authStore } = useStores();

    if(!authStore.payload?.accessToken) {
      return null;
    }

    const isAdmin = () => authStore.payload?.user?.type === UserTypes.ADMIN;

    const navigationLinks: Array<INavigationLinkProperties> = [
      {
        title: "Dashboard",
        icon: <DashboardIcon color={"primary"} />,
        href: "/",
        visible: true
      },
      {
        title: "Simplifications",
        icon: (<img src="/images/owl-no-right-wing.png" width={25} />),
        href: "/text-simplifications",
        visible: true
      },
      {
        title: "Exams",
        icon: <SummarizeIcon color={"primary"}  />,
        href: "/exams",
        visible: isAdmin()
      },
      {
        title: "Patients",
        icon: <PeopleIcon color={"primary"} />,
        href: "/patients",
        visible: isAdmin()
      },
      {
        title: "Doctors",
        icon: <DoctorIcon style={{ width: 22, color: theme.palette.primary.main }} />,
        href: "/doctors",
        visible: isAdmin()
      },
      {
        title: "Report Types",
        icon: <AssessmentIcon color={"primary"} />,
        href: "/report-types",
        visible: isAdmin()
      },
      {
        title: "Users",
        icon: <GroupsIcon color={"primary"} />,
        href: "/users",
        visible: isAdmin()
      },
      {
        title: "Simplification models",
        icon: <EngineeringIcon color={"primary"} />,
        href: "/text-simplification-contexts",
        visible: isAdmin()
      },
      {
        title: "CHAMP",
        icon: <ChampBottle style={{ width: 32, color: theme.palette.primary.main }} color={"primary"} />,
        href: "/champ",
        visible: isAdmin()
      },
    ];

    return <>(
        <AppBarContainer position="absolute" open={props.expanded}>
          <Toolbar
            sx={{
              pl: 0,
              // pr: '24px', // keep right padding when drawer closed
            }}
          >
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer}
                sx={{
                  // marginRight: '36px',
                  ...(props.expanded && { display: 'none' }),
                }}
              >
                <MenuIcon />
              </IconButton>
              <Box>
                <Typography
                  component="h1"
                  variant="h6"
                  color="inherit"
                  noWrap
                >
                  Vulgaroo Backoffice
                </Typography>
              </Box>
            <Box>
              <img style={{ width: "80px", marginLeft: 0, marginRight: 0 }} src="/images/robot_owl.webp" />
            </Box>
            <Box style={{ display: "flex", flex: 1, alignSelf: "center", alignItems: "flex-end", justifyContent: "flex-end" }}>
              <OwlImage width="120px" height="40px" />
            </Box>
          </Toolbar>
        </AppBarContainer>
        <Drawer variant="permanent" open={props.expanded}>
            <Toolbar
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                // px: [1],
            }}
            >
            <IconButton onClick={toggleDrawer}>
                <ChevronLeftIcon color={"primary"} />
            </IconButton>
            </Toolbar>

            <Divider />
            
            <List component="nav">
            <React.Fragment>
             {
                navigationLinks.filter(x => x.visible).map((element, index) => (
                  <Box key={`MenuItem_${index}`}><MenuItem {...element} /></Box>
                ))
              }
            </React.Fragment>
            </List>
        </Drawer>
    );
    </>
});

const MenuItem = (link: INavigationLinkProperties) => {
  const location = useLocation();
  const current = link.href?.toLowerCase() === location.pathname.toLowerCase()
  const themeProps = useThemeProperties();

  return <>
    <ReactLink to={link.href ?? ""} style={{ textDecorationLine: "initial" }}>
      <Box sx={{ backgroundColor: current ? themeProps.navMenuCurrentItemBackgroundColor : ""}} >
        <ListItemButton>
        <ListItemIcon>
            {
              link.icon
            }
        </ListItemIcon>
        <ListItemText primary={link.title} sx={{ color: themeProps.navMenuIconColor }} />
        </ListItemButton>
      </Box>
    </ReactLink>
    <Divider sx={{ my: 1 }} />
  </>
}

interface IClickableNavSubMenuProps {
  properties: INavigationLinkProperties;
  mainNavbarProps: INavigationSidebarProperties;
}

export function ClickableNavSubMenu(props: IClickableNavSubMenuProps) {
  const themeProps = useThemeProperties();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleOnMouseEnter = () => {
    // for now the user must click to expand the submenu
  };

  const handleOnMouseLeave = async () => {
    // hide the sub-menu after a short delay
    await new Promise((resolve) => setTimeout(resolve, 400)).then(() => {
      setOpen(false);
    });
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        setOpen(false);
      }}
    >
      <Tooltip title="" placement="right-end" onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}>
        <Box
          sx={{
            width: "100%",
          }}
        >
          <NavigationMenuItem
            icon={props.properties.icon}
            label={props.properties.title}
            expanded={props.mainNavbarProps.expanded}
            current={props.properties.href?.toLowerCase() === location.pathname.toLowerCase()}
            onClick={(event) => {
              event.preventDefault();

              setOpen(!open);

              if (props.properties.href) {
                props.mainNavbarProps.setExpanded(false);
                navigate(props.properties.href);
              }

              if (props.properties.externalHref) {
                window.open(props.properties.externalHref, "_blank");
              }
            }}
            submenu={props.properties.children ? true : false}
          />

          {props.properties.children && open && (
            <FadeInElement animation={"appear"} duration={90}>
              <Box
                sx={{
                  position: "absolute",
                  mt: "-3.0rem",
                  left: "calc(100% - 0.2rem)",
                  padding: 1,

                  border: "1px solid",
                  borderColor: themeProps.navMenuBorderColor,
                  borderRadius: 1,
                  background: themeProps.navMenuBackgroundColor,
                }}
              >
                {props.properties.children.map(child => (
                  <NavigationMenuItem
                    key={child.title}
                    icon={child.icon}
                    label={child.title}
                    expanded={true}
                    onClick={(event) => {
                      event.preventDefault();

                      setOpen(!open);

                      if (child.href) {
                        props.mainNavbarProps.setExpanded(false);
                        navigate(child.href);
                      }

                      if (child.externalHref) {
                        window.open(child.externalHref, "_blank");
                      }
                    }}
                  />
                ))}
              </Box>
            </FadeInElement>
          )}
        </Box>
      </Tooltip>
    </ClickAwayListener>
  );
}

export default AppBar;