import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../models";
import { Box, Button, TextField, Grid, Container, Typography, Select, MenuItem, SelectChangeEvent, InputLabel, FormControl, Autocomplete } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { useNavigate } from "react-router-dom";
import { translate } from "../../i18n";
import { ErrorValidationBloc } from "../errors/ErrorValidationBloc";
import { ControlledText } from "../fields/ControlledTextField";
import { LoadingBar } from "../LoadingBar";
import { UserDto } from "src/models/user/user";
import { __set } from "src/utils/objects";

interface IUserFormProps {
    title: string;
    userFields: UserDto;
}

interface IAuthProps {
    setUserFields: (value: UserDto) => void;
    userFields: UserDto;
}

const AuthFields = observer(({ userFields, setUserFields }: IAuthProps) => {
    const { clientStore, userStore, userTypeStore } = useStores();
    
    if (userFields.auth_type !== "password") {
        return null;
    }

    return (
        <Grid item xs={6}>
            <FormControl sx={{ width: '100%'}}>
            <ControlledText
                text={translate("user.password") ?? ""}
                store={userStore}
                errors={userStore.validationError?.errors ?? []}
                textFieldProps={{type: "password"}}
                fieldName="password"
                item={userFields}
                onChange={(value: UserDto) => setUserFields(value)}
                />
            </FormControl>
        </Grid>
    )
});

interface IAltFieldsProps extends IAuthProps { }

const AltFields = ({userFields, setUserFields}: IAltFieldsProps) => {

    const { clientStore, userStore, userTypeStore } = useStores();

    if (userFields.type === "api_consumer") {
        const selectedClient = clientStore.models?.find(x => x.id === userFields.related?.client_id);

        return (
            <Grid item xs={6}>
                <FormControl sx={{ width: '100%'}}>
                    <Autocomplete
                        freeSolo
                        disablePortal
                        options={clientStore.models?.map(x => x.name) ?? []}
                        value={selectedClient?.name ?? null}
                        onChange={(event: any, newValue: string | null) => {
                            const newClient = clientStore.models.find(x => x.name === newValue);

                            setUserFields(
                                __set(userFields, "related.client_id", newClient?.id)
                            )
                            // userStore.updateField(user, "related.client_id", newClient?.id)
                        }}
                        renderInput={(params) => <TextField {...params} label={translate('user.client')}/>}
                    />
                </FormControl>
            </Grid>
        )
    }

    return userFields.type === "admin" ? <> 
        <Grid item xs={6}>
            <ControlledText
                text={translate("user.lastname") ?? ""}
                store={userStore}
                errors={userStore.validationError?.errors ?? []}
                fieldName="related.lastname"
                item={userFields}
                onChange={(value: UserDto) => setUserFields(value)}
                />
        </Grid>
        <Grid item xs={6}>
            <ControlledText
                text={translate("user.firstname") ?? ""}
                store={userStore}
                errors={userStore.validationError?.errors ?? []}
                fieldName="related.firstname"
                item={userFields}
                onChange={(value: UserDto) => setUserFields(value)}    
            />
        </Grid>
                
     </> : null
}

export const UserForm = observer((props: IUserFormProps) => {

    const { clientStore, userStore, userTypeStore } = useStores();
    const navigate = useNavigate();
    const [userFields, setUserFields] = useState<UserDto>(props.userFields);
    const test: any = null;
    const testString: any = "";

    useEffect(() => {
        if(userTypeStore.loading) {
            return;
        }
        (async() => {
            await userTypeStore.getAll();
        })();
    }, [userTypeStore]);

    useEffect(() => {
        if(clientStore.loading) {
            return;
        }
        (async() => {
            await clientStore.getAll();
        })();
    }, [clientStore]);

    useEffect(() => {
        setUserFields(props.userFields);
    }, [props.userFields?.id]);

    const saveUser = async() => {

        try {
            await userStore.createOrUpdate(userFields);
            navigate("/users");

        } catch {

        }
    }

    return (
        <Box className="page" sx={{
            marginTop: 1,
          }}>
                {
                    userFields && (
                        <Box>
                            <LoadingBar isLoading={userStore.loading ?? false} />
                            <Typography  gutterBottom component="h1" variant="h5">
                                {props.title}
                            </Typography>
                            
                            <ErrorValidationBloc error={userStore.validationError} />
                            <Grid mb={2} mt={2} container spacing={3}>               
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%'}}>
                                    <InputLabel>{translate('user.type')}</InputLabel>
                                        <Select
                                            sx={{ width: '100%'}}
                                            value={userFields.type ?? ""}
                                            error={userStore.hasError("type")}
                                            disabled={(userFields.id && userFields.id > 0) ? true : false }
                                            onChange={(event: SelectChangeEvent) => {

                                                const type = userTypeStore.models.find(x => x.id === event.target.value);

                                                let newFields = __set(
                                                    userFields, 'type', event.target.value
                                                )

                                                newFields = __set(
                                                    newFields,  "auth_type", type.auth_type
                                                )

                                                setUserFields(newFields)
                                            }}
                                        >
                                            {
                                                userTypeStore?.models &&
                                                    userTypeStore.models.map((userType) => (
                                                        <MenuItem key={`UserTypeMenuItem_${userType.id}`} value={userType.id}>{userType.name}</MenuItem>
                                                    ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                
                                <AuthFields userFields={userFields} setUserFields={setUserFields} />

                                <Grid item xs={6}>
                                    <ControlledText
                                        text={translate("user.name") ?? ""}
                                        errors={userStore.validationError?.errors ?? []}
                                        store={userStore}
                                        fieldName="name"
                                        item={userFields}
                                        onChange={(value: UserDto) => setUserFields(value)} />
                                </Grid>
                                <Grid item xs={6}>
                                    <ControlledText
                                        text={translate("user.phone_number") ?? ""}
                                        errors={userStore.validationError?.errors ?? []}
                                        store={userStore}
                                        fieldName="phone_number" 
                                        item={userFields}
                                        onChange={(value: UserDto) => setUserFields(value)}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <ControlledText
                                        text={translate("user.email") ?? ""}
                                        errors={userStore.validationError?.errors ?? []}
                                        store={userStore}
                                        fieldName="email" 
                                        item={userFields}
                                        textFieldProps={{ disabled: (userFields.id && userFields.id > 0) ? true : false }}
                                        onChange={(value: UserDto) => setUserFields(value)} />
                                </Grid>

                                <AltFields userFields={userFields} setUserFields={setUserFields} />

                            </Grid>
                            <Container sx={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'space-between' }}>
                                <Button onClick={saveUser} variant="contained" sx={{ marginBottom: 2 }}  endIcon={<SaveIcon />}>
                                    Save
                                </Button>

                                <Button variant="outlined" onClick={() => navigate("/users")} startIcon={<CancelIcon />}>
                                    Cancel
                                </Button>
                                
                            </Container>
                        </Box>
                        )
                }
                
        </Box>
    )
});