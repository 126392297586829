import { Api, CrudRouteConfig, ICrudConfig } from "./api"
import { ApiCrudService } from "./api-crud-service"
import { Client } from "src/models/clients/client";

class ClientApiConfig implements ICrudConfig<Client> 
{
  getAll = new CrudRouteConfig<Client>("/admin/clients");
}

export class ClientApi extends ApiCrudService<Client> {
  constructor(api: Api) {
    super(api, new ClientApiConfig)
  }
}
