import { types } from "mobx-state-tree"
import { UserModel, User } from "./user"
import { UserApi } from "../../services/api/user-api"
import { BuildCrudModel } from "../CrudStore"
import { ICrudApi } from "src/services/api/api-crud-service";

const UserStoreModel = types
  .model("UserStore")

  const CrudModel = BuildCrudModel(UserModel, 
  {
    textCreateSuccess: () => "User created successfully",
    textUpdateSuccess: () => "User updated successfully"
  })
  .actions((self) => ({
    getApi: (): ICrudApi<User>|null => new UserApi(self.environment.api),
  }));
  
  export const CrudUserStoreModel = types.compose(CrudModel, UserStoreModel)