import { Api } from "./api"
import { GetExamResult, GetExamsResult, PostCommentResult, UpdateExamResult, PostImageResult, PostExamResult, PostTextsFromDocumentResult, TranslateExamResult, SimplifyTextResult, DeleteModelResult } from "./api.types"
import { getGeneralApiProblem } from "./api-problem"
import { Exam } from "../../models/exam/exam"

export class ExamApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }
  
  async get(id: number|string) : Promise<GetExamResult> {
    try {
      const response = await this.api.getExam(id as string)

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const payload = response.data

      return { kind: 'ok', payload }
    } catch (e) {
      return { kind: "bad-data" }
    }
  }

  async getAll() : Promise<GetExamsResult> {
    try {
      const response = await this.api.getExams()

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const payload = response.data

      return { kind: 'ok', payload }
    } catch (e) {
      return { kind: "bad-data" }
    }
  }


  async uploadImage(id: number, image: File): Promise<PostImageResult> {

    try {
      const response = await this.api.uploadImage(id, image)

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const payload = response.data

      return { kind: 'ok', payload }

    } catch (e) {
      return { kind: "bad-data" }
    }

  }

  async uploadTextsFromFile(id: number, provider: string, file: File, lang: string, selectedContextType: string, activateTextSimplification: boolean): Promise<PostTextsFromDocumentResult> {
    try {
      const response = await this.api.uploadExamTexts(id, provider, file, lang, selectedContextType, activateTextSimplification)

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const payload = response.data

      return { kind: 'ok', payload }

    } catch (e) {
      return { kind: "bad-data" }
    }
  }

  async addComment(id: number, comment: string): Promise<PostCommentResult> {
    try {
      const response = await this.api.addComment(id, comment)

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: 'ok' }
    } catch (e) {
      return { kind: "bad-data" }
    }
  }

  async updateStatus(id: number, status: string): Promise<UpdateExamResult> {
    try {

      const response = await this.api.updateStatust(id, status)

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }
      return { kind: 'ok', payload: response.data }
    } catch (e) {
      return { kind: "bad-data" }
    }
  }

  async translateExamTexts(exam: Exam, provider: string, lang: string) : Promise<TranslateExamResult> {
    try {
      const response = await this.api.translateExamTexts(exam, provider, lang);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const payload = response.data

      return { kind: 'ok', payload }
    } catch (e) {
      return { kind: "bad-data" }
    }
  }

  async simplifyExamTexts(exam: Exam, scientistText: string, model: string, contextType: string, lang: string) : Promise<SimplifyTextResult> {

    try {
      const response = await this.api.simplifyText(exam, scientistText, model, contextType, lang);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const payload = response.data

      return { kind: 'ok', payload }
    } catch (e) {
      return { kind: "bad-data" }
    }
  }

  async createOrUpdate(exam: Exam) : Promise<PostExamResult> {
    try {
      const response = exam.id && exam.id > 0 ? await this.api.updateExam(exam) : await this.api.createExam(exam);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const payload = response.data

      return { kind: 'ok', payload }
    } catch (e) {
      return { kind: "bad-data" }
    }
  }

  async delete(exam: Exam) : Promise<DeleteModelResult> {
    try {
      const response = await this.api.deleteExam(exam);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: 'ok' }
    } catch (e) {
      return { kind: "bad-data" }
    }
  }
}
