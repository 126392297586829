import { types } from "mobx-state-tree"
import { TextSimplificationModel, TextSimplification } from "./text-simplification"
import { TextSimplificationApi } from "../../services/api/text-simplification-api"
import { BuildCrudModel } from "../CrudStore"
import { ICrudApi } from "src/services/api/api-crud-service";
import { Api } from "src/services/api";

const TextSimplificationStoreModel = types
  .model("TextSimplificationStore")

const getApi = (baseApi: Api) => new TextSimplificationApi(baseApi)

  const CrudModel = BuildCrudModel(TextSimplificationModel)
    .actions((self) => ({
      async export() {
        self.setLoading(true)
        
        try {
          await getApi(self.environment.api).export()
        } catch (ex){
          self.setLoading(false)
          throw ex;
        }

        self.setLoading(false)
      }
    }))
    .actions((self) => ({
      getApi: (): ICrudApi<TextSimplification>|null => getApi(self.environment.api),
    }));
  
  export const CrudTextSimplificationStoreModel = types.compose(CrudModel, TextSimplificationStoreModel)