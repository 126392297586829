import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { Auth, AuthModel, AuthSnapshot } from "../auth/auth"
import { AuthApi } from "../../services/api/auth-api"
import { withEnvironment } from "../extensions/with-environment"
import { translate } from "../../i18n";

export const AuthStoreModel = types
  .model("AuthStore")
  .props({
    payload: types.optional(types.maybeNull(AuthModel), null),
    error: types.optional(types.maybeNull(types.string), null),
    loading:types.optional(types.maybeNull(types.boolean), null)
  })
  .extend(withEnvironment)
  .actions((self) => ({
    saveAuth: (AuthSnapshots: AuthSnapshot|null) => {
      self.loading = false
      self.payload = AuthSnapshots 
      
      if(self.payload?.expiresInSeconds) {
        let currentDate = new Date();
        currentDate.setSeconds(currentDate.getSeconds() + self.payload.expiresInSeconds);
        self.payload.expiresAt = currentDate.toISOString();
      }

      self.environment.api.setAccessToken(self.payload?.accessToken ?? null)
    },
    saveError: (error: string|null) => {
      self.loading = false
      self.error = error
    },
    saveLoading: (loading: boolean) => {
      self.loading = loading
      self.error = null
    }
  }))
  .actions((self) => ({
    login: async (username: string, passwd: string) => {

      if(!username || username === '') {
        return self.saveError(translate("errors.loginMissing"))
      }

      if(!passwd || passwd === '') {
        return self.saveError(translate("errors.passwordMissing"))
      }

      self.saveLoading(true)

      const api = new AuthApi(self.environment.api)
      const result = await api.login(username, passwd)

      if (result.kind === 'ok') {
        self.saveAuth(result.payload)
      } else {
        self.saveError(translate("errors.wrongIds"))
      }
    },

    checkOtpCode: async (userId: number, code: string): Promise<Auth | null> => {
      if(!code || code === '') {
        self.saveError(translate("errors.codeMissing"))
        return null;
      }

      self.saveLoading(true)

      const api = new AuthApi(self.environment.api)
      const result = await api.checkOtpCode(userId, code)

      if (result.kind === 'ok') {
        self.saveAuth(result.payload)

        return result.payload;
      } else {
        self.saveError(translate(`errors.wrongCode`))

        return null;
      }
    },

    clearError: () => self.saveError(null),

    clear: () =>{
      self.saveAuth(null)
      self.saveError(null)
    }
  }))

type AuthStoreType = Instance<typeof AuthStoreModel>
export interface AuthStore extends AuthStoreType {}
type AuthStoreSnapshotType = SnapshotOut<typeof AuthStoreModel>
export interface AuthStoreSnapshot extends AuthStoreSnapshotType {}
export const createAuthStoreDefaultModel = () => types.optional(AuthStoreModel, {})
