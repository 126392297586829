import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { CrudUserStoreModel } from "../user/user-store"
import { AuthStoreModel } from "../auth/auth-store"
import { CrudExamStoreModel } from "../exam/exam-store"
import { CrudReportTypeStoreModel } from "../reportTypes/report-type-store"
import { CrudDoctorStoreModel } from "../doctor/doctor-store"
import { AppStoreModel } from "../app-store/app-store"
import { LangStoreModel } from "../lang/lang-store"
import { CrudPatientStoreModel } from "../patient/patient-store"
import { MessageStoreModel } from "../message/message-store"
import { ContextTypeStoreModel } from "../context-types/context-type-store"
import { MedicalModelStoreModel } from "../medical-models/medical-model-store"
import { TranslationProviderStoreModel } from "../translations/translation-provider-store"
import { CrudUserTypeStoreModel } from "../user-types/user-type-store"
import { CrudClientStoreModel } from "../clients/client-store"
import { CrudTextSimplificationStoreModel } from "../text-simplifications/text-simplification-store"
import { CrudSimplificationContextStoreModel } from "../simplification-contexts/simplification-context-store"
import { ChampStoreModel } from "../champ/champ-store"

/**
 * A RootStore model.
 */
// prettier-ignore
export const RootStoreModel = types.model("RootStore").props({
  userStore: types.optional(CrudUserStoreModel, {} as any),
  authStore: types.optional(AuthStoreModel, {} as any),
  examStore: types.optional(CrudExamStoreModel, {} as any),
  reportTypeStore: types.optional(CrudReportTypeStoreModel, {} as any),
  doctorStore: types.optional(CrudDoctorStoreModel, {} as any),
  patientStore: types.optional(CrudPatientStoreModel, {} as any),
  langStore: types.optional(LangStoreModel, {} as any),
  appStore: types.optional(AppStoreModel, {} as any),
  messageStore: types.optional(MessageStoreModel, {} as any),
  contextTypeStore: types.optional(ContextTypeStoreModel, {} as any),
  medicalModelStore: types.optional(MedicalModelStoreModel, {} as any),
  translationProviderStore: types.optional(TranslationProviderStoreModel, {} as any),
  userTypeStore: types.optional(CrudUserTypeStoreModel, {} as any),
  clientStore: types.optional(CrudClientStoreModel, {} as any),
  textSimplificationStore: types.optional(CrudTextSimplificationStoreModel, {} as any),
  textSimplificationContextStore: types.optional(CrudSimplificationContextStoreModel, {} as any),
  champStore: types.optional(ChampStoreModel, {} as any),
})

/**
 * The RootStore instance.
 */
export interface RootStore extends Instance<typeof RootStoreModel> {}

/**
 * The data of a RootStore.
 */
export interface RootStoreSnapshot extends SnapshotOut<typeof RootStoreModel> {}
