import { useEffect } from "react";
import { useStores } from "../../models";
import { Box, Typography } from "@mui/material";
import { IColumn, CustomTable } from "src/components/lists/CustomTable";
import { Patient } from "../../models/patient/patient";
import { translate } from "../../i18n";
import { DATE_FORMAT_API, DATE_FORMAT_UI } from "../../config/env";
import dayjs from "dayjs";

export const PatientList = () => {
    const { patientStore } = useStores();

    const columns: IColumn<Patient>[] = [
        { field: 'ipp', headerName: 'IPP', width: 70, getValue: (patient: Patient) => patient.ipp ?? '' },
        { field: 'lastname', headerName: 'Lastname', width: 130, getValue: (patient: Patient) => patient.lastname ?? '' },
        { field: 'firstname', headerName: 'Firstname', width: 130, getValue: (patient: Patient) => patient.firstname ?? '' },
        { field: 'birthdate', headerName: 'Birthdate', width: 130, getValue: (patient: Patient) => {
            if(!patient.birthdate) {
                return ''
            }

            return dayjs(patient.birthdate, DATE_FORMAT_API).format(DATE_FORMAT_UI)
        }}
    ];

    useEffect(() => {
        (async() => {
            await patientStore.getAll();
        })();
    }, [patientStore]);

    return (
        <Box className="page">
            <Typography variant="h1" component="h1" gutterBottom>
                { translate('patient.list') }
            </Typography>
            {
                patientStore.models && (
                    <CustomTable<Patient>
                        columns={columns}
                        data={patientStore.models}
                        id={(patient: Patient) => patient.id.toString()}
                        addEditModal={
                            {
                                store: patientStore,
                                columns: [
                                    {
                                        attr: 'ipp',
                                        label: translate('patient.ipp') ?? ''
                                    },
                                    {
                                        attr: 'lastname',
                                        label: translate('patient.lastname') ?? ''
                                    },
                                    {
                                        attr: 'firstname',
                                        label: translate('patient.firstname') ?? ''
                                    },
                                    {
                                        type: "date",
                                        attr: 'birthdate',
                                        label: translate('patient.birthdate') ?? ''
                                    }
                                ]
                            }
                        }
                        deleteModal={
                            {
                                title: (patient: Patient) => translate('common.delete', { "element": patient?.ipp ?? '' }) ?? "",
                                store: patientStore
                            }
                        }
                        actions={{width: 130}}
                    />
                )
            }
            
                
        </Box>
    )
}