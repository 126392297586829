import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { ParagraphModel } from "../sentence/paragraph"

/**
 * Conclusion model.
 */
export const ConclusionModel = types.model("Conclusion").props({
    level: types.string,
    paragraphs: types.array(types.maybeNull(ParagraphModel))
})

type ConclusionType = Instance<typeof ConclusionModel>
export interface Conclusion extends ConclusionType {}
type ConclusionSnapshotType = SnapshotOut<typeof ConclusionModel>
export interface ConclusionSnapshot extends ConclusionSnapshotType {}
export const createExamDefaultModel = () => types.optional(ConclusionModel, {} as ConclusionType)