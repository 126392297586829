import { useEffect } from "react";
import { useStores } from "../../models";
import { Backdrop, Box, Button, Card, CircularProgress, Typography } from "@mui/material";
import { IColumn, CustomTable } from "src/components/lists/CustomTable";
import { TxKeyPath, translate } from "../../i18n";
import { DATE_FORMAT_WITH_TIME_API, DATE_FORMAT_WITH_TIME_UI } from "../../config/env";
import dayjs from "dayjs";
import { TextSimplification } from "src/models/text-simplifications/text-simplification";
import { observer } from "mobx-react";

export const NpsScreen = observer(() => {
    const { champStore } = useStores();

    useEffect(() => {
        (async() => {
            await champStore.getNps();
        })();
    }, [champStore]);

    const exportFeedbacks = async() => {
        await champStore.export();
    };

    return (
        <Box className="page">
            <Typography variant="h1" component="h1" gutterBottom>
                { translate('champ.index') }
            </Typography>

            {
                champStore.loading &&
                (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={true}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                )
            }
            
            <Box sx={{marginBottom: 2}}>
                <Button component="label" variant="contained" onClick={exportFeedbacks}>
                    Export
                </Button>
            </Box>
            {
                champStore.value && (
                    <Card>
                        <Typography variant="h4" component="h3" gutterBottom dangerouslySetInnerHTML={{ __html: translate('champ.counters', champStore.value) ?? '' }}>
                        </Typography>
                    </Card>
                )
            }
        </Box>
    )
})