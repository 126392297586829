import { Box, Button, Modal, Grid, TextField, Alert } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import SaveIcon from '@mui/icons-material/Save';
import { translate, TxKeyPath } from "../../i18n";
import { observer } from "mobx-react";
import { useState } from "react";
import { ModelInstanceType, _NotCustomized } from "mobx-state-tree";
import dayjs from "dayjs";
import { DATE_FORMAT_API, DATE_FORMAT_UI } from "../../config/env";
import { ICrudActions } from "src/models/CrudStore";

export interface IEditableColumn 
{
    attr: string;
    label: string;
    type?: "text" | "date"
}

interface IEditModalProps<ModelType> {
    model: ModelType|null;
    setModel: (model: ModelType|null) => void;
    store: ModelInstanceType<any, ICrudActions<ModelType>>;
    columns: IEditableColumn[]
}

export const EditModal = observer(({ model, setModel, store, columns }: IEditModalProps<any>) => {

    const [error, setError] = useState<string|null>(null);
    
    const save = async() => {
        setError(null);

        if (model) {
            try {
                await store.createOrUpdate(model);
                setModel(null);
            }
            catch(e: any) {
                setError(translate(`errors.api.${e.message}` as TxKeyPath));
            }
        }
    };

    const updateField = (field: string, value: string|null|number|undefined|string[]) => {
        if (model) {
            setModel(store.updateField(model, field, value));
        }
    }

    const valueToString = (value: any) => {
        if(!value) {
            return "";
        }

        return value as string ?? "";
    }

    return (
        <Modal
            open={model !== null}
            onClose={() => {
                setModel(null);
                setError(null);
            }}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
        >
            <Box sx={{ 
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                borderRadius: 10,
                boxShadow: 24,
                p: 4 }}>
                    {
                        error && (
                            <Alert sx={{ mb: 2 }} severity="error">{ error }</Alert>
                        )
                    }
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            {
                                columns.map((x, index) => {
                                 switch(x.type) 
                                 {
                                    case null:
                                    case undefined:
                                    case "text":
                                        return (<TextField
                                            value={model && valueToString(model[x.attr])}
                                            key={`Text_${index}`}
                                            sx={{ width: '100%', mb: 2 }}
                                            onChange={(event) => updateField(x.attr, event.target.value)}
                                            {...x}
                                        />)
                                    case "date":
                                        return (
                                            <DatePicker
                                                {...x}
                                                key={`Date_${index}`}
                                                label={translate('patient.birthdate')}
                                                onChange={(value) => {
                                                    updateField('birthdate', dayjs(value, DATE_FORMAT_UI).format(DATE_FORMAT_API))
                                                }}
                                                inputFormat={DATE_FORMAT_UI}
                                                value={model && dayjs(valueToString(model[x.attr]) ?? '', DATE_FORMAT_API).format("MM/DD/YYYY") + " 01:01:01"}
                                                renderInput={(params) => <TextField sx={{ width: '100%'}} {...params} />}
                                            />
                                        )
                                }})
                            }
                            
                        </Grid>
                        <Grid item xs={12}>
                            <Button onClick={save} variant="outlined" sx={{mt: 2, width: '100%'}} endIcon={ <SaveIcon />}>
                                { translate('common.save') }
                            </Button>
                        </Grid>
                    </Grid>
            </Box>
        </Modal>
    )
});