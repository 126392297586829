import { IModelType, Instance, SnapshotOut, cast, types } from "mobx-state-tree"
import { MedicalModel, MedicalModelModel, MedicalModelSnapshot } from "./medical-model"
import { MedicalModelApi } from "../../services/api/medical-model-api"
import { withEnvironment } from "../extensions/with-environment"
import { translate } from "../../i18n"

/**
 * store containing MedicalModels
 */
export const MedicalModelStoreModel = types
  .model("MedicalModelStore")
  .props({
    medicalModels: types.optional(types.array(MedicalModelModel), []),
    loading:types.optional(types.maybeNull(types.boolean), null)
  })
  .extend(withEnvironment)
  .actions((self) => ({
    saveMedicalModels: (medicalModelSnapshots: MedicalModelSnapshot[]) => {
      self.loading = false

      self.medicalModels = cast(medicalModelSnapshots)
    },
    saveLoading: (loading: boolean) => {
      self.loading = loading
    }
  }))
  .actions((self) => ({
    getMedicalModels: async () => {
      self.saveLoading(true)

      const api = new MedicalModelApi(self.environment.api)

      const result = await api.getAll()

      if(result.kind === 'ok') {
        return self.saveMedicalModels(result.payload);
      }
    },

    clearLoading: () => self.saveLoading(false),
  }))

type MedicalModelStoreType = Instance<typeof MedicalModelStoreModel>
export interface MedicalModelStore extends MedicalModelStoreType {}
type MedicalModelStoreSnapshotType = SnapshotOut<typeof MedicalModelStoreModel>
export interface MedicalModelStoreSnapshot extends MedicalModelStoreSnapshotType {}
export const createMedicalModelStoreDefaultModel = () => types.optional(MedicalModelStoreModel, {} as MedicalModelStoreType)
