import { ChangeEvent, ChangeEventHandler, useEffect, useRef, useState } from "react";
import { useStores } from "../../models";
import { Backdrop, Box, Button, CircularProgress, Typography } from "@mui/material";
import { IColumn, CustomTable } from "src/components/lists/CustomTable";
import { translate } from "../../i18n";
import { observer } from "mobx-react";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { SimplificationContext } from "src/models/simplification-contexts/simplification-context";
import { VisuallyHiddenInput } from "src/components/VisuallyHiddenInput";
import { ErrorValidationBloc } from "src/components/errors/ErrorValidationBloc";

export const SimplificationContextList = observer(() => {
    const { textSimplificationContextStore, contextTypeStore, medicalModelStore } = useStores();

    const columns: IColumn<SimplificationContext>[] = [
        { field: 'context', headerName: 'Context', width: 130, getValue: (simplificationContext: SimplificationContext) => simplificationContext.context },
        { field: 'context_type', headerName: 'Context type', width: 130, getValue: (simplificationContext: SimplificationContext) => simplificationContext.type?.code ?? "" },
        { 
            field: 'engines', 
            headerName: 'Models', 
            width: 130, 
            getValue: (simplificationContext: SimplificationContext) => simplificationContext.engines.map(x => x?.code ?? "").join(", ") ?? "" 
        },
    ];

    const [jsonFileRef, setJsonFileRef] = useState<HTMLInputElement | undefined | null>(null);

    const reload = async() => {
        contextTypeStore.getContextTypes().then(async() => {
            medicalModelStore.getMedicalModels().then(async() => {
                await textSimplificationContextStore.getAll();
            })
        }) 
    }

    useEffect(() => {
        (async() => {
            await reload();      
        })();
    }, [textSimplificationContextStore, contextTypeStore, medicalModelStore]);

    const importJson = async(e: any) => {
        if (e) console.log(e.target.files);

        if(!e.target.files || !e.target.files.length) {
            return;
        }

        try {
            await textSimplificationContextStore.uploadJsonFile(e.target.files[0], async function() {
                await reload();
            });
        }
        catch (ex)
        {
            console.error(ex)
        }
        finally {
            if (jsonFileRef) {
                // clear selection
                jsonFileRef.files = null;
                jsonFileRef.value = "";
            }
        }
    }

    return (
        <Box className="page">
            <Typography variant="h1" component="h1" gutterBottom>
                { translate('text_simplification_context.list') }
            </Typography>

            {
                textSimplificationContextStore.loading &&
                (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={true}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                )
            }
            
            <ErrorValidationBloc error={textSimplificationContextStore.validationError} />

            <Button 
                component="label" 
                variant="contained"
                onChange={importJson}
                startIcon={<CloudUploadIcon />}>
                    Import JSON File
                    <VisuallyHiddenInput 
                    type="file"
                    accept="application/json" 
                    ref={ elt => setJsonFileRef(elt) } onChange={importJson} />
            </Button>

            {
                textSimplificationContextStore.models && (
                    <CustomTable<SimplificationContext>
                        columns={columns}
                        data={textSimplificationContextStore.models}
                        id={(simplificationContext: SimplificationContext) => simplificationContext.id?.toString() ?? ''}
                        editButton={{
                            redirectLink: (simplificationContext: SimplificationContext) => `/text-simplification-contexts/${simplificationContext.id}`
                        }}
                        deleteModal={
                            {
                                title: (simplificationContext: SimplificationContext) => translate('common.delete', { "element": simplificationContext ? simplificationContext.context : '' }) ?? "",
                                store: textSimplificationContextStore
                            }
                        }
                        actions={{width: 130}}
                    />
                )
            }
        </Box>
    )
})