import { useEffect } from "react";
import { Exam } from "../../models/exam/exam";
import { useStores } from "../../models";
import { Box, Typography } from "@mui/material";
import { translate } from "src/i18n";
import { CustomTable, IColumn } from "src/components/lists/CustomTable";

export const ExamList = () => {
    const { examStore } = useStores();

    const columns: IColumn<Exam>[] = [
        { field: 'type', headerName: 'Type', width: 70, getValue: (exam: Exam) => exam.type ?? '' },
        { field: 'exam_date', headerName: 'Date', width: 70, getValue: (exam: Exam) => exam.exam_date ?? '' },
        { field: 'exam_id', headerName: 'Exam ID', width: 130, getValue: (exam: Exam) => exam.exam_id ?? '' },
    ];

    useEffect(() => {
        (async() => {
            await examStore.getAll();
        })()
    }, [examStore]);

    return (
        <Box className="page" sx={{ mt: 1 }}>
            <Typography variant="h1" component="h1" gutterBottom>
                { translate('exam.list') }
            </Typography>
            {
                examStore.models && (
                    <CustomTable<Exam>
                        columns={columns}
                        data={examStore.models}
                        id={(model: Exam) => {
                            return model.id.toString()
                        }}
                        addButton={{
                            text: translate('exam.create') ?? '',
                            redirectLink: () => "/exams/create"
                        }}
                        editButton={{
                            redirectLink: (exam: Exam) => `/exams/${exam.exam_id}/edit`
                        }}
                        deleteModal={
                            {
                                title: (exam: Exam) =>  translate('common.delete', { "element": exam?.exam_id ?? '' }) ?? "",
                                store: examStore
                            }
                        }
                        actions={{width: 130}}
                    />
                )
            }
        </Box>
    )
}