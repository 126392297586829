import { IMSTArray, Instance, types } from "mobx-state-tree"
import { ContextTypeDto, ContextTypeModel } from "../context-types/context-type"
import { MedicalModelDto, MedicalModelModel } from "../medical-models/medical-model"

export const SimplificationTextExampleModel = types.model("SimplificationTextExampleModel").props({
  id: types.identifierNumber,
  example: types.string,
  simplification: types.string
})

export const SimplificationContextModel = types.model("SimplificationContextModel").props({
  id: types.identifierNumber,
  context: types.string,
  type: types.maybeNull(types.safeReference(ContextTypeModel)),
  engines: types.array(types.safeReference(MedicalModelModel)),
  examples: types.maybe(types.array(SimplificationTextExampleModel))
}).actions((self) => ({
  toJSON() {
    // Manually create a plain JavaScript object to represent the model
    return {
      id: self.id,
      context: self.context,
      type: self.type ? (self.type as any).toJSON() : self.type,
      engines: self.engines ? self.engines.map(x => (x as any).toJSON()) : [],
      examples: self.examples ? self.examples.map(x => (x as any).toJSON()) : []
    };
  },
}))

type SimplificationTextExampleType = Instance<typeof SimplificationTextExampleModel>
export interface SimplificationTextExample extends SimplificationTextExampleType {}

type SimplificationContextType =  Instance<typeof SimplificationContextModel>
export interface SimplificationContext extends SimplificationContextType {}

export interface SimplificationTextExampleDto {
  id: number;
  example: string;
  simplification: string;
}

export interface SimplificationContextDto {
  id: number;
  context: string;
  type: ContextTypeDto | null;
  engines: MedicalModelDto[];
  examples: SimplificationTextExampleDto[]
}
