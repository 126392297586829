import "./App.css";
import { Box, Container, createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Toolbar from '@mui/material/Toolbar';
import AppBar from './components/AppBar';
import { useEffect, useState } from 'react';
import { ScrollTop } from './components/ScrollTop';
import { RootStore, RootStoreProvider, setupRootStore } from "./models"
import { BrowserRouter } from 'react-router-dom';
import { AppNavigator } from "./navigators";

const mdTheme = createTheme({
  components: {
    MuiCard: {
      styleOverrides:{ 
        root: {
          borderTop: "4px solid #f57d3d;"
        }
      },
    }
  },
  palette: {
    background: {
      default: '#FFFFFF'
    },
    primary: {
      main: '#f57d3d',
    },
    secondary: {
      main: '#edf2ff',
    },
  },
});


function App() {
  
  const [scrollTarget, setScrollTarget] = useState<Node | Window | undefined>(undefined)

  const [rootStore, setRootStore] = useState<RootStore | undefined>(undefined)

  const [navExpanded, setNavExpanded] = useState<boolean>(false);

  // Kick off initial async loading actions, like loading fonts and RootStore
  useEffect(() => {
    ;(async () => {
      setupRootStore().then(setRootStore)
    })()
  }, [setRootStore])

  if(!rootStore) return null;
  
  return (
    <BrowserRouter>
      <ThemeProvider theme={mdTheme}>
        <CssBaseline  />
        <RootStoreProvider value={rootStore}>
          <Box sx={{ display: 'flex' }}>
            
            <AppBar expanded={navExpanded} setExpanded={setNavExpanded} />
            
            <Box
              ref={(node: Node) => {
                if (node) {
                  setScrollTarget(node);
                }
              }}
              component="main"
              sx={{
                ml: 0,
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
              }}
            >
            <Toolbar id="back-to-top-anchor" />
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Container disableGutters maxWidth="lg" sx={{ mb: 4, ml: 0 }}>
                <AppNavigator />
              </Container>
            </LocalizationProvider>
            </Box>
            <ScrollTop scrollTarget={scrollTarget} />
          </Box>
        </RootStoreProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
