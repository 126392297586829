import { Api } from "./api"
import { GetReportTypesResult, GetReportTypeResult, DeleteModelResult } from "./api.types"
import { getGeneralApiProblem } from "./api-problem"
import { ReportType } from "src/models/reportTypes/report-type"

export class ReportTypeApi {
  private api: Api 

  constructor(api: Api) {
    this.api = api
  }
  
  async get(id: number|string): Promise<GetReportTypeResult> {
    throw new Error("get for ReportTypeApi is not implemented");
  }

  async getAll() : Promise<GetReportTypesResult> {
    try {
      const response = await this.api.getReportTypes()

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const payload = response.data

      return { kind: 'ok', payload }
    } catch (e) {
      return { kind: "bad-data" }
    }
  }

  async createOrUpdate(reportType: ReportType) : Promise<GetReportTypeResult> {
    try {
      const response = reportType.id ? await this.api.updateReportType(reportType) : await this.api.createReportType(reportType);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const payload = response.data

      return { kind: 'ok', payload }
    } catch (e) {
      return { kind: "bad-data" }
    }
  }

  async delete(reportType: ReportType) : Promise<DeleteModelResult> {
    try {
      const response = await this.api.deleteReportType(reportType);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: 'ok' }
    } catch (e) {
      return { kind: "bad-data" }
    }
  }
}
