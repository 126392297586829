import { TextSimplification } from "src/models/text-simplifications/text-simplification";
import { Api, CrudRouteConfig, ICrudConfig } from "../api"
import FileDownload from 'js-file-download';
import { GetChampNpsResult } from "../api.types";
import { getGeneralApiProblem } from "../api-problem";

export class ChampApi {

  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async exportFeedbacks() {
    const response = await this.api.exportChampFeedbacks()

    if(!response.ok) 
    {
      throw new Error("An error occured");
    }

    const filename = "champ-feedbacks.xlsx";
    
    FileDownload(response.data, filename);
  }
  
  async getNps() : Promise<GetChampNpsResult> {
    try {
      const response = await this.api.getChampNps()

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const payload = response.data

      return { kind: 'ok', payload }
    } catch (e) {
      return { kind: "bad-data" }
    }
  }
}
