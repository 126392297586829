import { Instance, SnapshotOut, types } from "mobx-state-tree";

export const TranslationProviderModel = types.model("TranslationProvider").props({
    id: types.identifierNumber,
    code: types.maybe(types.string),
    name: types.maybe(types.string),
    is_default: types.maybe(types.boolean),
})

type TranslationProviderType = Instance<typeof TranslationProviderModel>
export interface TranslationProvider extends TranslationProviderType {}
type TranslationProviderSnapshotType = SnapshotOut<typeof TranslationProviderModel>
export interface TranslationProviderSnapshot extends TranslationProviderSnapshotType {}
export const createTranslationProviderDefaultModel = () => types.optional(TranslationProviderModel, {} as TranslationProviderType)