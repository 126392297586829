import { SimplificationContext } from "src/models/simplification-contexts/simplification-context";
import { Api, CrudRouteConfig, ICrudConfig } from "./api"
import { ApiCrudService, GetTModelResult } from "./api-crud-service"

class SimplificationContextApiConfig implements ICrudConfig<SimplificationContext> 
{
  getAll = new CrudRouteConfig<SimplificationContext>("/admin/text-simplification-contexts");
  get = (id: number|string) => new CrudRouteConfig<SimplificationContext>("/admin/text-simplification-contexts/" + id);
  createOrUpdate = (simplificationContext: SimplificationContext) => new CrudRouteConfig<SimplificationContext>("/admin/text-simplification-contexts/" + simplificationContext.id, simplificationContext);
  delete = (simplificationContext: SimplificationContext) => 
    new CrudRouteConfig<SimplificationContext>("/admin/text-simplification-contexts/" + simplificationContext.id);
}

export class SimplificationContextApi extends ApiCrudService<SimplificationContext> {
  constructor(api: Api) {
    super(api, new SimplificationContextApiConfig)
  }

  async uploadSimplificationContextFromJsonContent(content: any): Promise<GetTModelResult<SimplificationContext>> {
    const self = this;

    return await this.performRequest(async function() {
      return await self.api.uploadSimplificationContextFromJsonContent(content);
    });
  }
}
