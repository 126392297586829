import { Instance, SnapshotOut, types } from "mobx-state-tree"

import { ConclusionListModel } from "../conclusions/conclusion-list"
import { PatientModel } from '../patient/patient'
import { DoctorModel } from '../doctor/doctor'

/**
 * Rick and Exam model.
 */
export const ExamModel = types.model("Exam").props({
  id: types.identifierNumber,
  exam_id: types.optional(types.maybeNull(types.string), null),
  type: types.optional(types.maybeNull(types.string), null),
  hash: types.optional(types.maybeNull(types.string), null),
  image: types.optional(types.maybeNull(types.string), null),
  // image_url: types.optional(types.maybeNull(types.string), null),
  // image_url: types.maybe(types.string),
  logo: types.optional(types.maybeNull(types.string), null),
  place: types.optional(types.maybeNull(types.string), null),
  exam_date: types.optional(types.maybeNull(types.string), null),
  report_date: types.optional(types.maybeNull(types.string), null),
  indication: types.optional(types.maybeNull(types.string), null),
  comment: types.optional(types.maybeNull(types.string), null),
  diagnostic: types.optional(types.maybeNull(DoctorModel), null),
  prescriber: types.optional(types.maybeNull(DoctorModel), null),
  responsible: types.optional(types.maybeNull(DoctorModel), null),

  patient: types.optional(types.maybeNull(PatientModel), null),
  texts:  types.optional(types.maybeNull(ConclusionListModel), null),

  readed: types.boolean,
  understood: types.boolean,
}).actions(self => {
  function getScientistText(languageCode: string) {
    const sentences = self.texts?.scientist?.paragraphs.filter(p => p?.lang == languageCode).map(p => p?.sentences) ?? []

    return sentences.join(' ')
  }

  return {
    getScientistText
  }
})
// .extend(withEnvironment)

type ExamType = Instance<typeof ExamModel>
export interface Exam extends ExamType {}
export interface ExamUpdate {
  readed: boolean,
  understood: boolean,
}
type ExamSnapshotType = SnapshotOut<typeof ExamModel>
export interface ExamSnapshot extends ExamSnapshotType {}
export const createExamDefaultModel = () => types.optional(ExamModel, {} as ExamType)

