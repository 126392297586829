// APP THEME
export const DARK_MODE_THEME = "dark";
export const LIGHT_MODE_THEME = "light";

const palette = {
    vulgarooGreen: "#00a688",
    white: "white"
}

// LIGHT MODE
export const LIGHT_SELECTED_NAVIGATION_COLOR = palette.vulgarooGreen;
export const LIGHT_NAVIGATION_BAR_BORDER_COLOR = palette.white;
export const LIGHT_NAVIGATION_BAR_BACKGROUND_COLOR = palette.white;
export const LIGHT_NAVIGATION_BAR_SELECTED_BACKGROUND_COLOR = palette.vulgarooGreen;

// DARK MODE
export const DARK_SELECTED_NAVIGATION_COLOR = palette.vulgarooGreen;
export const DARK_NAVIGATION_BAR_BORDER_COLOR = palette.white;
export const DARK_NAVIGATION_BAR_BACKGROUND_COLOR = palette.white;
export const DART_NAVIGATION_BAR_SELECTED_BACKGROUND_COLOR = palette.vulgarooGreen;