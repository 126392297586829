import { ReactNode, useEffect } from "react";
import { observer } from "mobx-react";
import { Box, Backdrop, CircularProgress, Typography, Container, Button, Grid } from "@mui/material";
import { useNavigate, useParams } from 'react-router-dom';
import { ModelInstanceType } from "mobx-state-tree";
import { ICrudActions } from "src/models/CrudStore";
import { LoadingBar } from "./LoadingBar";
import { ErrorValidationBloc } from "./errors/ErrorValidationBloc";
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

interface ICreateOrEditFormParams<ModelType>
{
    elementRouteKeyId: string;
    store: ModelInstanceType<any, ICrudActions<ModelType>>;
    children: ReactNode;
    title: string;
    navigateLink: string;
    model: ModelType;
}

export const CreateOrEditFormContainer = observer(function<ModelType>(props: ICreateOrEditFormParams<ModelType>) {
    const params = useParams();
    const elementId: string = params[props.elementRouteKeyId] ?? "";
    
    useEffect(() => {

        if(!props.store || !elementId || props.store.loading) {
            return;
        }

        (async() => {
            await props.store.get(elementId);
        })();
    }, [props.store, elementId]);

    const navigate = useNavigate();

    const save = async() => {
        try {
            await props.store.createOrUpdate(props.model);

            props.store.clear();

            return navigate(props.navigateLink)
        } catch(ex) {
            console.error(ex)
        }
    }

    const cancel = () => {
        props.store.clear();
        
        return navigate(props.navigateLink) 
    }

    return (
        <Box className="page" sx={{
            marginTop: 8,
            
          }}>
                {
                    props.store.loading &&
                    (
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={true}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    )
                }
                {
                    props.model && (
                        <Box>
                            <LoadingBar isLoading={props.store.loading ?? false} />
                            <Typography  gutterBottom component="h1" variant="h5">
                                {props.title}
                            </Typography>
                            
                            <ErrorValidationBloc error={props.store.validationError} />
                            
                            <Grid mb={2} mt={2} pr={2} container spacing={3}>
                                {props.children}
                            </Grid>
                            
                            <Container sx={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'space-between' }}>
                                <Button onClick={save} variant="contained" sx={{ marginBottom: 2 }}  endIcon={<SaveIcon />}>
                                    Save
                                </Button>

                                <Button onClick={cancel} variant="outlined" startIcon={<CancelIcon />}>
                                    Cancel
                                </Button>
                            </Container>
                        </Box>
                    )
                }
                
        </Box>
    )
});