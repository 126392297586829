import { Instance, types } from "mobx-state-tree"

const TextSimplificationFeedbackModel = types.model("TextSimplificationFeedbackModel").props({
  id: types.identifierNumber,
  new_text: types.maybe(types.maybeNull(types.string)),
  note: types.number,
  accepted: types.boolean
});

export const TextSimplificationModel = types.model("TextSimplification").props({
  id: types.identifierNumber,
  lang: types.string,
  medical_model: types.string,
  medical_context_type: types.string,
  user: types.string,
  scientist_text: types.string,
  simplified_text: types.string,
  elapsed_time_in_seconds: types.number,
  ip_address: types.string,
  date: types.maybe(types.maybeNull(types.string)),
  billed_at: types.maybe(types.maybeNull(types.string)),
  feedback: types.maybe(types.maybeNull(TextSimplificationFeedbackModel))
})

type TextSimplificationType = Instance<typeof TextSimplificationModel>
export interface TextSimplification extends TextSimplificationType {}