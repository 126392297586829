import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { withEnvironment } from "../extensions/with-environment"
import { AppSettingsModel } from "./appSettings"

/**
 * store containing Apps
 */
export const AppStoreModel = types
  .model("AppStore")
  .props({
    settings: types.optional(types.maybeNull(AppSettingsModel), {})
  })
  .extend(withEnvironment)
  .actions((self) => ({
    updateTheme: (theme: string) => {
      self.settings = { theme };
    }
  }))

type AppStoreType = Instance<typeof AppStoreModel>
export interface AppStore extends AppStoreType {}
type AppStoreSnapshotType = SnapshotOut<typeof AppStoreModel>
export interface AppStoreSnapshot extends AppStoreSnapshotType {}
export const createAppStoreDefaultModel = () => types.optional(AppStoreModel, {})
