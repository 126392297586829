import { types } from "mobx-state-tree"
import { Patient, PatientModel } from "./patient"
import { PatientApi } from "../../services/api/patient-api"
import { BuildCrudModel } from "../CrudStore";
import { ICrudApi } from "src/services/api/api-crud-service"

const CrudModel = BuildCrudModel(PatientModel)
.actions((self) => ({
  getApi: (): ICrudApi<Patient>|null => new PatientApi(self.environment.api),
}));

/**
 * store containing Patients
 */
const PatientStoreModel = types.model("PatientStore")

export const CrudPatientStoreModel = types.compose(CrudModel, PatientStoreModel)
