// import * as Localization from "expo-localization"
import { I18n } from "i18n-js";
import en from "./en.json";
import ja from "./ja.json";
import fr from "./fr.json";

export const i18n = new I18n( { en, ja, fr, "fr-FR": fr, "en-US": en });

i18n.enableFallback = true;

// i18n.locale = Localization.locale || "en"

i18n.defaultLocale = "en";
i18n.locale = "en";

/**
 * Builds up valid keypaths for translations.
 * Update to your default locale of choice if not English.
 */
type DefaultLocale = typeof en
export type TxKeyPath = RecursiveKeyOf<DefaultLocale>

//@ts-ignore
export type RecursiveKeyOf<TObj extends Record<string, any>> = {
  [TKey in keyof TObj & string]: TObj[TKey] extends Record<string, any> //@ts-ignore
    ? `${TKey}` | `${TKey}.${RecursiveKeyOf<TObj[TKey]>}` //@ts-ignore
    : `${TKey}`
}[keyof TObj & string]

export const setLanguage = (lang: string) => {
  i18n.locale = i18n.translations[lang] ? lang : i18n.locale;
}

export const getLanguage = (): string => i18n.locale;
