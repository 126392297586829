import { Alert, Box, Card } from "@mui/material"
import { useRef } from "react"
import { ValidationError } from "src/models/error/error-validation"

interface IProps {
    error: ValidationError | null
}

export const ErrorValidationBloc = (props: IProps) => {
    const errorBlocRef = useRef<HTMLDivElement>(null)

    return (
        <Box ref={errorBlocRef} id="error-message" sx={{p: 1}}>
        {
            props.error && (
                <Card sx={{p: 1}}>
                    <Box >
                        <Alert sx={{ mb: 2 }} severity="error">{ props.error.message }</Alert>
                    </Box>
                </Card>
            )
        }
        </Box>
    )
}