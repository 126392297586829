import { Instance, SnapshotOut, cast, types } from "mobx-state-tree"
import { TranslationProviderModel, TranslationProviderSnapshot } from "./translation-provider"
import { TranslationProviderApi } from "../../services/api/translation-provider-api"
import { withEnvironment } from "../extensions/with-environment"

/**
 * store containing TranslationProviders
 */
export const TranslationProviderStoreModel = types
  .model("TranslationProviderStore")
  .props({
    translationProviders: types.optional(types.array(TranslationProviderModel), []),
    loading:types.optional(types.maybeNull(types.boolean), null)
  })
  .extend(withEnvironment)
  .actions((self) => ({
    saveTranslationProviders: (translationProviderSnapshots: TranslationProviderSnapshot[]) => {
      self.loading = false

      self.translationProviders = cast(translationProviderSnapshots)
    },
    saveLoading: (loading: boolean) => {
      self.loading = loading
    }
  }))
  .actions((self) => ({
    getTranslationProviders: async () => {
      self.saveLoading(true)

      const api = new TranslationProviderApi(self.environment.api)

      const result = await api.getAll()

      if(result.kind === 'ok') {
        return self.saveTranslationProviders(result.payload)
      }
    }
  }))

type TranslationProviderStoreType = Instance<typeof TranslationProviderStoreModel>
export interface TranslationProviderStore extends TranslationProviderStoreType {}
type TranslationProviderStoreSnapshotType = SnapshotOut<typeof TranslationProviderStoreModel>
export interface TranslationProviderStoreSnapshot extends TranslationProviderStoreSnapshotType {}
export const createTranslationProviderStoreDefaultModel = () => types.optional(TranslationProviderStoreModel, {} as TranslationProviderStoreType)
