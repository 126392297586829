// Define a generic mapper function from DTO to model
export function fromDTO<ModelType extends { create: (data: any) => any }, DTOType>(
    Model: ModelType,
    dto: DTOType
  ): ReturnType<ModelType['create']> {
    // Create a new instance of the model with DTO data
    return Model.create(dto);
  }
  
  // Define a generic mapper function from model to DTO
  export function toDTO<ModelType extends { toJSON: () => any }, DTOType>(
    model: ModelType
  ): DTOType {
    // Convert model to JSON
    return model.toJSON() as DTOType;
  }