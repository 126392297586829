// const API_URL = process.env.NODE_ENV == 'development' ?
//     'http://192.168.1.54:8000/api' : 'http://vulgaroo-dev.us-east-1.elasticbeanstalk.com/api'

// const API_URL = 'http://192.168.1.54:8000/api'
const API_URL = 'http://vulgaroo-dev.us-east-1.elasticbeanstalk.com/api'

module.exports = {
    API_URL: process.env.REACT_APP_API_URL || API_URL,
    OAUTH_CLIENT_ID: process.env.REACT_APP_OAUTH_CLIENT_ID,
    OAUTH_CLIENT_SECRET: process.env.REACT_APP_OAUTH_CLIENT_SECRET,
    DATE_FORMAT_API: "YYYY-MM-DD",
    DATE_FORMAT_WITH_TIME_API: "YYYY-MM-DD HH:MM:ss",
    DATE_FORMAT_UI: "DD/MM/YYYY",
    DATE_FORMAT_WITH_TIME_UI: "DD/MM/YYYY HH:MM:ss",
    TINYMCE_KEY: "cyuesauzc1ku4faklhn2n8a6d2fdnewk45lmmm2itlvozi9h"
}