import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../models";
import { TextField, Grid, Select, MenuItem, SelectChangeEvent, InputLabel, FormControl, IconButton } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import { translate } from "../../i18n";
import { ControlledText } from "../fields/ControlledTextField";
import { MedicalModel, MedicalModelDto } from "src/models/medical-models/medical-model";
import { SimplificationContextDto, SimplificationTextExample, SimplificationTextExampleDto } from "src/models/simplification-contexts/simplification-context";
import { TextSimplification } from "src/models/text-simplifications/text-simplification";
import { __set } from "src/utils/objects";
import { IAnyModelType } from "mobx-state-tree";
import { toDTO } from "src/models/model-dto-mapper";
import { ContextTypeDto } from "src/models/context-types/context-type";

interface ISimplificationContextFormProps {
    simplificationContext: SimplificationContextDto;
    setSimplificationContext: (simplificationContextDto: SimplificationContextDto) => void;
}

export const SimplificationContextForm = observer(({ simplificationContext, setSimplificationContext }: ISimplificationContextFormProps) => {

    const { textSimplificationContextStore, contextTypeStore, medicalModelStore } = useStores();

    useEffect(() => {
        if(!contextTypeStore || contextTypeStore.loading) 
        {
            return;
        }

        (async () => {
            await contextTypeStore.getContextTypes();
        })();
    }, [contextTypeStore]);

    return <>
        <Grid item xs={11.6}>
            <TextField
                fullWidth={true}
                label={translate("text_simplification_context.context") ?? ""}
                value={simplificationContext.context}
                rows={3}
                multiline={true}
                onChange={event => {
                    setSimplificationContext(Object.assign({...simplificationContext}, {
                        context: event.target.value
                    }))
                }}
            />
        </Grid>
        <Grid item xs={5.8}>             
            <FormControl sx={{ width: '100%'}}>
            <InputLabel>{translate('text_simplification_context.models')}</InputLabel>
                <Select
                    sx={{ width: '100%'}}
                    value={simplificationContext.engines.map((x: MedicalModelDto) => x.code)}
                    error={textSimplificationContextStore.hasError("engines")}
                    multiple
                    onChange={(event) => {
                        const engines = medicalModelStore.medicalModels.filter(x => x.code ? event.target.value.includes(x.code) : false)

                        setSimplificationContext(Object.assign({...simplificationContext}, {
                            engines: engines.map((x) => toDTO<any, MedicalModelDto>(x))
                        }))
                    }}
                >
                    {
                        medicalModelStore?.medicalModels &&
                            medicalModelStore.medicalModels.map((model) => (
                                <MenuItem key={`UserTypeMenuItem_${model.code}`} value={model.code}>{model.code} - {model.name}</MenuItem>
                            ))
                    }
                </Select>
            </FormControl>
        </Grid>
        <Grid item xs={5.8}>                       
            <FormControl sx={{ width: '100%'}}>
            <InputLabel>{translate('text_simplification_context.type')}</InputLabel>
                <Select
                    sx={{ width: '100%'}}
                    value={simplificationContext.type?.code ?? ""}
                    error={textSimplificationContextStore.hasError("type")}
                    onChange={(event: SelectChangeEvent) => {

                        const type = contextTypeStore.contextTypes.find(x => x.code === event.target.value);

                        setSimplificationContext(Object.assign({...simplificationContext}, {
                            type: toDTO<any, ContextTypeDto>(type)
                        }))

                    }}
                >
                    {
                        contextTypeStore?.contextTypes &&
                            contextTypeStore.contextTypes.map((type) => (
                                <MenuItem key={`UserTypeMenuItem_${type.code}`} value={type.code}>{type.code} - {type.description}</MenuItem>
                            ))
                    }
                </Select>
            </FormControl>
            
        </Grid>

        {
            simplificationContext.examples && simplificationContext.examples.map((x: SimplificationTextExample) => 
                (
                    <Grid key={`GridExample_${x.id}`} m={0} container spacing={3}>
                    <Grid item xs={5.8} key={`Grid_1_${x.id}`}>
                        <FormControl sx={{ width: '100%'}}>
                            <TextField
                                label={translate("text_simplification_context.example") ?? ""}
                                rows={3} 
                                multiline={true}
                                value={x.example}
                                onChange={ event => {
                                    
                                    const examples = simplificationContext.examples.map((example: SimplificationTextExampleDto) => 
                                        example.id === x.id ? {...x, example: event.target.value } : example
                                    );

                                    setSimplificationContext(Object.assign({...simplificationContext}, {
                                        examples: examples
                                    }))
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={5.8} key={`Grid_2_${x.id}`}>
                        <FormControl sx={{ width: '100%'}}>
                            <TextField
                                label={translate("text_simplification_context.simplification") ?? ""}
                                rows={3} 
                                multiline={true}
                                value={x.simplification}
                                onChange={ event => {
                                    
                                    const examples = simplificationContext.examples.map((example: SimplificationTextExampleDto) => 
                                        example.id === x.id ? {...x, simplification: event.target.value } : example
                                    );

                                    setSimplificationContext(Object.assign({...simplificationContext}, {
                                        examples: examples
                                    }))
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item sx={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center" }} xs={0.2} key={`Grid_3_${x.id}`}>
                        <IconButton color="primary" sx={{ alignSelf: "center" }} onClick={() => {
                            const examples = simplificationContext.examples.filter((example: SimplificationTextExampleDto) => example.id !== x.id);

                            setSimplificationContext(Object.assign({...simplificationContext}, {
                                examples: examples
                            }))
                        }} >
                            <ClearIcon color="primary"  />
                        </IconButton>
                    </Grid>
                    </Grid>
                )
            )
        }
    </>
});