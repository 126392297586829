import { Instance, SnapshotOut, cast, types } from "mobx-state-tree"
import { ContextType, ContextTypeModel, ContextTypeSnapshot } from "./context-type"
import { ContextTypeApi } from "../../services/api/context-type-api"
import { withEnvironment } from "../extensions/with-environment"
import { translate } from "../../i18n"

/**
 * store containing ContextTypes
 */
export const ContextTypeStoreModel = types
  .model("ContextTypeStore")
  .props({
    contextTypes: types.optional(types.array(ContextTypeModel), []),
    loading:types.optional(types.maybeNull(types.boolean), null)
  })
  .extend(withEnvironment)
  .actions((self) => ({
    saveContextTypes: (contextTypeSnapshots: ContextTypeSnapshot[]) => {
      self.loading = false

      self.contextTypes = cast(contextTypeSnapshots)
    },
    saveLoading: (loading: boolean) => {
      self.loading = loading
    }
  }))
  .actions((self) => ({
    getContextTypes: async () => {
      self.saveLoading(true)

      const api = new ContextTypeApi(self.environment.api)

      const result = await api.getAll()

      if(result.kind === 'ok') {
        return self.saveContextTypes(result.payload);
      }
    },

    clearLoading: () => self.saveLoading(false)
  }))

type ContextTypeStoreType = Instance<typeof ContextTypeStoreModel>
export interface ContextTypeStore extends ContextTypeStoreType {}
type ContextTypeStoreSnapshotType = SnapshotOut<typeof ContextTypeStoreModel>
export interface ContextTypeStoreSnapshot extends ContextTypeStoreSnapshotType {}
export const createContextTypeStoreDefaultModel = () => types.optional(ContextTypeStoreModel, {} as ContextTypeStoreType)
